import attachOutline from '@iconify/icons-eva/attach-outline';
import barChart2Outline from '@iconify/icons-eva/bar-chart-2-outline';
import percentOutline from '@iconify/icons-eva/percent-outline';
import shoppingCartOutline from '@iconify/icons-eva/shopping-cart-outline';
import swapOutline from '@iconify/icons-eva/swap-outline';
import { Icon, IconifyIcon } from '@iconify/react';
import { useParams } from 'react-router-dom';

import { GenericNavigation } from '~/components/layout/generic-navigation';
import { routes, RoutesEnum } from '~/constants/routes';
import { putUrlParam } from '~/lib/url';

export const NftNavigation = () => {
  const nftTabs: Array<[RoutesEnum, IconifyIcon]> = [
    [RoutesEnum.NFT_ASSETS, attachOutline],
    [RoutesEnum.NFT_OFFERS, shoppingCartOutline],
    [RoutesEnum.NFT_AUCTIONS, barChart2Outline],
    [RoutesEnum.NFT_ROYALTIES, percentOutline],
    [RoutesEnum.NFT_TRANSFER, swapOutline],
  ];

  const { nftId } = useParams<{ nftId: string }>();

  return (
    <GenericNavigation
      tabs={nftTabs.map(([route, icon]) => ({
        url: putUrlParam(routes[route].url, { nftId }),
        title: routes[route].title ?? '',
        icon: <Icon icon={icon} width={20} height={20} />,
      }))}
    />
  );
};
