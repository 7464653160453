import { useContext } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { AppContext } from '~/context/app-context';
import { formatError } from '~/lib/formatters';
import { useMessages } from '~/lib/notificator';
import { connectionManager } from '~/model/wallet';
import { WalletConnector } from '~/model/wallet/types';

export const useWalletConnect = (): ((
  connector: WalletConnector
) => Promise<void>) => {
  const { saveUserPubKey } = useContext(AppContext);
  const { showMessage } = useMessages();
  const history = useHistory();
  const location = useLocation<{ from: string }>();

  return async (connector: WalletConnector) => {
    try {
      const account = await connectionManager.connect(connector);
      saveUserPubKey(account);
      history.push(location.state?.from || `/account/${account}/minted`);
    } catch (error) {
      showMessage(`Failed to connect. ${formatError(error)}`, 'error');
    }
  };
};

export const useWalletDisconnect = (): (() => Promise<void>) => {
  const { saveUserPubKey } = useContext(AppContext);
  return async () => {
    await connectionManager.disconnect();
    saveUserPubKey('');
  };
};
