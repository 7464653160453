import { NFTAttachment } from '@cere/freeport-sdk';
import { Box, Button, CircularProgress, FormLabel, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { FormApi } from 'final-form';
import { useCallback, useContext, useMemo, useState } from 'react';
import { Form } from 'react-final-form';
import { Redirect } from 'react-router-dom';

import { Input, NumberInput, Textarea } from '~/components/form/fields';
import { CollectionField } from '~/components/form/fields/collection-field';
import { FileDropzoneField } from '~/components/form/fields/file-uploader/file-dropzone-field';
import { SimpleForm } from '~/components/form/simple-form';
import { PageLayoutV2 } from '~/components/layout/page/page-layout-v2';
import { routes, RoutesEnum } from '~/constants/routes';
import { AppContext } from '~/context/app-context';
import { useFileUpload } from '~/context/file-upload-context';
import { formatError } from '~/lib/formatters';
import { useMessages } from '~/lib/notificator';
import { putUrlParam } from '~/lib/url';
import { stringToDataHexString } from '~/lib/utils';
import { FormConfirmationDialog } from '~/routes/mint/form-confirmation-dialog';
import { getCollectionFactory } from '~/services/get-collection-factory';
import { getNftAttachment } from '~/services/get-nft-attachment';
import { Collection, defaultCollection } from '~/types/collection';

export type FormValues = {
  name: string;
  description: string;
  assets: File[];
  previews: File[];
  supply: number;
  collection: Collection;
};

const useStyles = makeStyles(() => ({
  confirmHeader: {
    fontSize: '1.5rem',
    marginTop: '1rem',
  },
  confirmListHeader: {
    fontSize: '1rem',
  },
  confirmListText: {
    fontSize: '1rem',
    margin: '0.3rem 0 2rem',
  },
  confirmationAssets: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit, max-content)',
  },
}));

export const Mint = () => {
  const { showMessage } = useMessages();
  const { userPubKey, analyticsService } = useContext(AppContext);
  const [open, setOpen] = useState(false);
  const [created, setCreated] = useState(false);
  const [uploadProgress, setUploadProgress] = useState<number>(0);
  const upload = useFileUpload();
  const styles = useStyles();

  const closeConfirmation = useCallback(() => {
    setOpen(false);
  }, []);

  const askConfirmation = useCallback(() => {
    setOpen(true);
  }, []);

  const createNft = useCallback(
    async (values: FormValues, api: FormApi<FormValues, FormValues>) => {
      try {
        const { name, description, assets, supply, previews, collection } =
          values;

        const factoryContract = await getCollectionFactory();
        const mintingTx = await factoryContract.mintOnBehalf(
          collection.address,
          userPubKey,
          supply,
          [0]
        );
        const receipt = await mintingTx.wait();
        const onBehalfEvent = receipt?.events?.find(
          (event) => event?.event === 'MintOnBehalf'
        );
        if (!onBehalfEvent) {
          throw new Error(
            'Something wrong with transaction. MintOnBehalf not found!'
          );
        }

        const mintedNftId: string | undefined = BigInt(
          onBehalfEvent?.args?.[3]?.toString()
        ).toString();

        if (!mintedNftId) {
          throw new Error('Something wrong with transaction');
        }

        showMessage('Your transaction has been confirmed');

        const [assetFile] = assets;
        const [previewFile] = previews;

        const cid = await upload(
          {
            title: name,
            description,
            assetFile,
            previewFile,
          },
          setUploadProgress
        );

        const contract: NFTAttachment = await getNftAttachment();
        const attachmentTx = await contract.collectionManagerAttachToNFT(
          BigInt(mintedNftId),
          stringToDataHexString(cid)
        );
        await attachmentTx.wait();

        analyticsService.track('NFT_CREATED');
        api.restart();
        setCreated(true);
      } catch (error) {
        showMessage(`Error occurred. ${formatError(error)}`, 'error');
      }
    },
    [analyticsService, showMessage, upload, userPubKey]
  );

  const submit = useCallback(
    async (values: FormValues, api: FormApi<FormValues, FormValues>) => {
      closeConfirmation();
      return createNft(values, api);
    },
    [closeConfirmation, createNft]
  );

  const initialValues: FormValues = useMemo(
    () => ({
      name: '',
      description: '',
      supply: 0,
      assets: [],
      previews: [],
      collection: defaultCollection,
    }),
    []
  );

  if (created) {
    return (
      <Redirect
        to={putUrlParam(routes[RoutesEnum.ACCOUNT_MINTED].url, {
          accountId: userPubKey,
        })}
      />
    );
  }

  return (
    <PageLayoutV2 title="Create new item">
      <Box sx={{ my: 5 }}>
        <Form initialValues={initialValues} onSubmit={submit}>
          {({ handleSubmit, valid, submitting, pristine, invalid, values }) => (
            <SimpleForm
              formId="create-nft"
              submitDisabled={pristine}
              onSubmit={handleSubmit}
              hideSubmit
              submitButtonTitle="Create"
              isValid={() => valid}
              actionInProgress={submitting}
            >
              <Box>
                <FormLabel component="div">
                  <Box sx={{ display: 'inline-block', mb: 1, ml: 1 }}>
                    Image, Video or Audio
                  </Box>
                  <FileDropzoneField
                    accept="image/*,video/*,audio/*"
                    name="assets"
                    maxFiles={1}
                    uploadProgress={uploadProgress}
                    helperText="File types supported: JPG, PNG, GIF, SVG, MP4, WEBM, MP3, WAV, OGG, GLB, GLTF."
                  />
                </FormLabel>
              </Box>
              <Box sx={{ my: 4, alignItems: 'center' }}>
                <FormLabel>
                  <Box sx={{ display: 'inline-block', mb: 1, ml: 1 }}>
                    Name *
                  </Box>
                  <Input name="name" required />
                </FormLabel>
              </Box>
              <Box sx={{ my: 4, alignItems: 'center' }}>
                <FormLabel>
                  <Box sx={{ display: 'inline-block', mb: 1, ml: 1 }}>
                    Description *
                  </Box>
                  <Textarea
                    helperText="This description will be shown on the item’s detail page underneath the file"
                    name="description"
                    rows={4}
                    required
                  />
                </FormLabel>
              </Box>
              <Grid container spacing={1}>
                <Grid item sm={6}>
                  <FormLabel>
                    <Box>Collection *</Box>
                  </FormLabel>
                  <CollectionField />
                </Grid>
                <Grid item sm={6}>
                  <FormLabel>
                    <Box>Quantity *</Box>
                  </FormLabel>
                  <NumberInput
                    helperText="The number of copies to be minted"
                    name="supply"
                    min={1}
                    inputMode="numeric"
                  />
                </Grid>
              </Grid>
              <Box>
                <FormLabel component="div">
                  <Box sx={{ display: 'inline-block', mb: 1, ml: 1 }}>
                    Cover image
                  </Box>
                  <FileDropzoneField
                    accept="image/*"
                    name="previews"
                    maxFiles={1}
                    helperText="This image will be on the cover of the item"
                  />
                </FormLabel>
              </Box>
              <Box sx={{ display: 'flex', justifyContent: 'center', my: 5 }}>
                <Button
                  disabled={invalid || submitting}
                  onClick={askConfirmation}
                  size="large"
                  variant="contained"
                >
                  <span>Create</span>
                  {submitting && <CircularProgress sx={{ ml: 1 }} size={24} />}
                </Button>
              </Box>
              <FormConfirmationDialog
                open={open}
                onClose={closeConfirmation}
                styles={styles}
                values={values}
              />
            </SimpleForm>
          )}
        </Form>
      </Box>
    </PageLayoutV2>
  );
};
