import arrowBackOutline from '@iconify/icons-eva/arrow-back-outline';
import { Icon } from '@iconify/react';
import { Button, Container, Divider, Stack, Typography } from '@mui/material';
import { useCallback } from 'react';
import { Form } from 'react-final-form';

import { Input, NumberInput } from '~/components/form/fields';
import { SimpleForm } from '~/components/form/simple-form';
import { HelpPopover } from '~/components/icons/help-popover';
import { FlexBox } from '~/components/layout/flex-box';
import { PageLayout } from '~/components/layout/page-layout';
import { formatError } from '~/lib/formatters';
import { useMessages } from '~/lib/notificator';
import { TOKEN_TITLE } from '~/lib/utils';
import { configureRoyalties } from '~/services/royalties.service';
import { Royalties } from '~/types/royalties';
import { Token } from '~/types/token';

type FormValues = {
  primaryRoyaltyAccount: string;
  primaryRoyaltyCut: number;
  primaryRoyaltyMinimum: number;
  secondaryRoyaltyAccount: string;
  secondaryRoyaltyCut: number;
  secondaryRoyaltyMinimum: number;
};

type Props = {
  nft: Token;
  royalties: Royalties;
  onBack: () => Promise<unknown>;
};

const MIN_FRACTION_VALUE = 1;
const MAX_FRACTION_VALUE = 10_000;
const MIN_INPUT_VALUE = (MIN_FRACTION_VALUE / MAX_FRACTION_VALUE) * 100;

export const ConfigureRoyalties = ({ nft, royalties, onBack }: Props) => {
  const { showMessage } = useMessages();
  const { nftId, collectionAddress } = nft;

  const getInitialValues = () => ({
    ...royalties,
    primaryRoyaltyCut: (royalties.primaryRoyaltyCut / MAX_FRACTION_VALUE) * 100,
    secondaryRoyaltyCut:
      (royalties.secondaryRoyaltyCut / MAX_FRACTION_VALUE) * 100,
  });

  const submit = useCallback(
    async (values: FormValues) => {
      try {
        const tx = await configureRoyalties(
          nftId,
          {
            ...values,
            primaryRoyaltyCut:
              (values.primaryRoyaltyCut / 100) * MAX_FRACTION_VALUE,
            secondaryRoyaltyCut:
              (values.secondaryRoyaltyCut / 100) * MAX_FRACTION_VALUE,
          },
          collectionAddress
        );
        await tx.wait();
        showMessage(
          `Transaction submitted successfully with hash: ${tx.hash}!`,
          'success'
        );
        await onBack();
      } catch (error) {
        showMessage(
          `Failed to configure royalties. ${formatError(error)}`,
          'error'
        );
      }
    },
    [collectionAddress, nftId, onBack, showMessage]
  );

  return (
    <PageLayout title="Configure royalties">
      <Container sx={{ mb: 5 }}>
        <Button
          onClick={onBack}
          variant="outlined"
          startIcon={<Icon icon={arrowBackOutline} />}
          sx={{ mb: 3 }}
        >
          Back
        </Button>
        <Container maxWidth="sm">
          <Typography paragraph sx={{ px: 1 }}>
            Royalties can be configured in two parts (both optional), being: a
            share of the sale price for an NFT (offers) and a minimum royalty
            per transfer. For simple transfers that do not involve a (high
            enough) sale minimum royalty is charged. The shares are defined in
            percentages, while the minimums are defined in USDC.
          </Typography>
          <Typography paragraph sx={{ px: 1 }}>
            Transfer is primary if it comes from the creator of this NFT
            (normally the first sale after creation). Otherwise it is a
            secondary transfer.
          </Typography>
          <Typography sx={{ px: 1, mb: 5 }}>
            There can be one beneficiary account for each primary and secondary
            royalty division. Create a joint account first if you want to
            distribute revenues between multiple parties.
          </Typography>
          <Form onSubmit={submit} initialValues={getInitialValues()}>
            {({ handleSubmit, pristine, submitting, hasValidationErrors }) => (
              <SimpleForm
                submitDisabled={pristine || hasValidationErrors || submitting}
                onSubmit={handleSubmit}
                submitButtonTitle="Configure royalties"
                actionInProgress={submitting}
                isValid={() => !hasValidationErrors}
              >
                <Stack spacing={3} mb={3}>
                  <FlexBox>
                    <Input
                      name="primaryRoyaltyAccount"
                      label="Primary Royalty Account"
                      required
                    />
                    <HelpPopover>
                      <Typography sx={{ p: 2, maxWidth: 240 }}>
                        A beneficiary account for primary royalties. Account
                        must be a Ethereum/Polygon address
                      </Typography>
                    </HelpPopover>
                  </FlexBox>
                  <FlexBox>
                    <NumberInput
                      name="primaryRoyaltyCut"
                      label="Primary Royalty Cut"
                      min={MIN_INPUT_VALUE}
                      max={100}
                      inputMode="decimal"
                      suffix="%"
                      required
                    />
                    <HelpPopover>
                      <Typography sx={{ m: 2, maxWidth: 240 }}>
                        A cut of the primary sale price in basis points (0.01%).
                      </Typography>
                      <Divider />
                      <Typography sx={{ m: 2 }}>
                        Type: a number from 0 to 10000
                      </Typography>
                    </HelpPopover>
                  </FlexBox>
                  <FlexBox>
                    <NumberInput
                      name="primaryRoyaltyMinimum"
                      label="Primary Royalty Minimum"
                      inputMode="numeric"
                    />
                    <HelpPopover>
                      <Typography sx={{ p: 2, maxWidth: 250 }}>
                        Minimum royalty that is charged for primary sale, being
                        a number higher than 0 in USDC.
                      </Typography>
                      <Typography sx={{ p: 2, maxWidth: 250 }}>
                        This is not compatible with auctions.
                      </Typography>
                      <Divider />
                      <Typography sx={{ m: 2 }}>
                        Type: a number from 0 in {TOKEN_TITLE} tokens
                      </Typography>
                    </HelpPopover>
                  </FlexBox>
                  <FlexBox>
                    <Input
                      name="secondaryRoyaltyAccount"
                      label="Secondary Royalty Account"
                      required
                    />
                    <HelpPopover>
                      <Typography sx={{ p: 2, maxWidth: 240 }}>
                        A beneficiary account for secondary royalties. Account
                        must be a Ethereum/Polygon address
                      </Typography>
                    </HelpPopover>
                  </FlexBox>
                  <FlexBox>
                    <NumberInput
                      name="secondaryRoyaltyCut"
                      label="Secondary Royalty Cut"
                      min={MIN_INPUT_VALUE}
                      max={100}
                      inputMode="decimal"
                      suffix="%"
                      required
                    />
                    <HelpPopover>
                      <Typography sx={{ m: 2, maxWidth: 240 }}>
                        A cut of the secondary sale price in basis points
                        (0.01%).
                      </Typography>
                      <Divider />
                      <Typography sx={{ m: 2 }}>
                        Type: a number from 0 to 10000
                      </Typography>
                    </HelpPopover>
                  </FlexBox>
                  <FlexBox>
                    <NumberInput
                      name="secondaryRoyaltyMinimum"
                      label="Secondary Royalty Minimum"
                      inputMode="numeric"
                    />
                    <HelpPopover>
                      <Typography sx={{ p: 2, maxWidth: 250 }}>
                        Minimum royalty that is charged for secondary sale,
                        being a number higher than 0 in USDC.
                      </Typography>
                      <Typography sx={{ p: 2, maxWidth: 250 }}>
                        This is not compatible with auctions.
                      </Typography>
                      <Divider />
                      <Typography sx={{ m: 2 }}>
                        Type: a number from 0 in {TOKEN_TITLE} tokens
                      </Typography>
                    </HelpPopover>
                  </FlexBox>
                </Stack>
              </SimpleForm>
            )}
          </Form>
        </Container>
      </Container>
    </PageLayout>
  );
};
