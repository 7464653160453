import { Backdrop, CircularProgress, TextField } from '@mui/material';
import Autocomplete, {
  AutocompleteRenderInputParams,
  createFilterOptions,
} from '@mui/material/Autocomplete';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { Field } from 'react-final-form';

import { API_URL } from '~/constants/env';
import { useUserPublicKey } from '~/context/app-context';
import { useMessages } from '~/lib/notificator';
import { get } from '~/lib/request';
import { getCollectionFactory } from '~/services/get-collection-factory';

import { required } from './validators/required';

interface CollectionFieldProps {
  name: string;
  address: string;
}

const getOwnCollections = async (
  account: string
): Promise<CollectionFieldProps[]> =>
  (await (
    await get(`/wallet/${account}/collections`)
  ).json()) as CollectionFieldProps[];

const filter = createFilterOptions<CollectionFieldProps>();

export const CollectionField = () => {
  const userPubKey = useUserPublicKey();
  const [isLoading, setLoading] = useState<boolean>(false);
  const [collections, setCollections] = useState([] as CollectionFieldProps[]);
  const { showMessage } = useMessages();

  useEffect(() => {
    getOwnCollections(userPubKey)
      .then(setCollections)
      .catch((error: Error) => {
        showMessage(
          `error during fetching collections by wallet -  ${error.message}`,
          'error'
        );
      });
  }, [showMessage, userPubKey]);

  return (
    <Field<CollectionFieldProps | null>
      validate={(state) => required(state?.name ?? '')}
      name="collection"
      type="text"
    >
      {({ input, meta }) => {
        const helperText =
          'Select or add new collection in which your item will appear';
        const helpText: unknown = meta.touched
          ? meta.error || helperText
          : helperText;

        return (
          <>
            <Backdrop
              sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={isLoading}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
            <Autocomplete<CollectionFieldProps, false, false, true>
              onChange={(_, newValue) => {
                async function createCollection(collectionName: string) {
                  setLoading(true);
                  showMessage(
                    `Collection ${collectionName} is going to be created`,
                    'info'
                  );
                  const collectionFactory = await getCollectionFactory();

                  const tx = await collectionFactory.createCollection(
                    userPubKey,
                    collectionName,
                    `${API_URL}/nft/`,
                    ''
                  );
                  const receipt = await tx.wait();
                  const address: string | undefined =
                    receipt?.events?.[6].args?.[1]?.toString();

                  input.onChange({
                    name: collectionName,
                    address,
                  });
                  setLoading(false);
                  showMessage('Your collection has been created', 'success');
                }

                // eslint-disable-next-line unicorn/consistent-function-scoping
                const onError = (error: Error) => {
                  showMessage(
                    `error during collection creation ${error.message}`,
                    'error'
                  );
                  setLoading(false);
                };

                if (typeof newValue === 'string') {
                  createCollection(newValue).catch(onError);
                } else if (newValue?.address === '') {
                  createCollection(newValue.name).catch(onError);
                } else input.onChange(newValue);
              }}
              filterOptions={(options, params) => {
                const filtered = filter(options, params);

                const { inputValue } = params;
                // Suggest the creation of a new value
                const isExisting = options.some(
                  (option) => inputValue === option.name
                );
                if (inputValue !== '' && !isExisting) {
                  filtered.push({
                    address: '',
                    name: inputValue,
                  });
                }

                return filtered;
              }}
              selectOnFocus
              freeSolo
              handleHomeEndKeys
              options={collections}
              getOptionLabel={(option) => option.name}
              renderOption={(props, option) => (
                // eslint-disable-next-line react/jsx-props-no-spreading
                <li {...props}>
                  {option.address ? option.name : `Add "${option.name}"`}
                </li>
              )}
              renderInput={(params: AutocompleteRenderInputParams) => (
                <TextField
                  id={params.id}
                  color="primary"
                  disabled={params.disabled}
                  error={meta.touched && meta.invalid}
                  fullWidth
                  helperText={helpText ? String(helpText) : ''}
                  onBlur={input.onBlur}
                  onChange={input.onChange}
                  inputProps={params.inputProps}
                  /* eslint-disable-next-line react/jsx-no-duplicate-props */
                  InputProps={params.InputProps}
                  size={params.size}
                  type={input.type}
                  multiline={input.multiple}
                  value={input.value}
                />
              )}
            />
          </>
        );
      }}
    </Field>
  );
};
