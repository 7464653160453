import React from 'react';

export const MetamaskIcon = () => (
  <svg
    width="20"
    height="19"
    viewBox="0 0 20 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.6558 0.142822L11.1506 5.69614L12.5463 2.42339L18.6558 0.142822Z"
      fill="#E17726"
      stroke="#E17726"
      strokeWidth="0.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1.34448 0.142822L8.78286 5.74796L7.45406 2.42338L1.34448 0.142822Z"
      fill="#E27625"
      stroke="#E27625"
      strokeWidth="0.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.9535 13.0189L13.9565 16.0695L18.2325 17.2469L19.4574 13.0856L15.9535 13.0189Z"
      fill="#E27625"
      stroke="#E27625"
      strokeWidth="0.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M0.549805 13.0856L1.76726 17.2469L6.0358 16.0695L4.04628 13.0189L0.549805 13.0856Z"
      fill="#E27625"
      stroke="#E27625"
      strokeWidth="0.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.80566 7.86558L4.61792 9.65746L8.84932 9.84998L8.70829 5.29626L5.80566 7.86558Z"
      fill="#E27625"
      stroke="#E27625"
      strokeWidth="0.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.1942 7.86554L11.2471 5.24438L11.1506 9.84994L15.382 9.65742L14.1942 7.86554Z"
      fill="#E27625"
      stroke="#E27625"
      strokeWidth="0.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.03589 16.0698L8.59697 14.8333L6.39223 13.1155L6.03589 16.0698Z"
      fill="#E27625"
      stroke="#E27625"
      strokeWidth="0.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.4031 14.8333L13.9567 16.0698L13.6078 13.1155L11.4031 14.8333Z"
      fill="#E27625"
      stroke="#E27625"
      strokeWidth="0.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.9567 16.0696L11.4031 14.8331L11.6109 16.4917L11.5886 17.1951L13.9567 16.0696Z"
      fill="#D5BFB2"
      stroke="#D5BFB2"
      strokeWidth="0.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.03589 16.0696L8.41143 17.1951L8.39658 16.4917L8.59697 14.8331L6.03589 16.0696Z"
      fill="#D5BFB2"
      stroke="#D5BFB2"
      strokeWidth="0.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.45591 12.0192L6.33276 11.3972L7.83231 10.7086L8.45591 12.0192Z"
      fill="#233447"
      stroke="#233447"
      strokeWidth="0.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.5442 12.0192L12.1678 10.7086L13.6748 11.3972L11.5442 12.0192Z"
      fill="#233447"
      stroke="#233447"
      strokeWidth="0.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.03587 16.0696L6.40707 13.0189L4.04639 13.0856L6.03587 16.0696Z"
      fill="#CC6228"
      stroke="#CC6228"
      strokeWidth="0.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.5928 13.0189L13.9565 16.0696L15.9535 13.0856L13.5928 13.0189Z"
      fill="#CC6228"
      stroke="#CC6228"
      strokeWidth="0.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.382 9.65735L11.1506 9.84986L11.5441 12.0194L12.1677 10.7088L13.6746 11.3974L15.382 9.65735Z"
      fill="#CC6228"
      stroke="#CC6228"
      strokeWidth="0.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.33275 11.3974L7.83229 10.7088L8.45589 12.0194L8.84932 9.84986L4.61792 9.65735L6.33275 11.3974Z"
      fill="#CC6228"
      stroke="#CC6228"
      strokeWidth="0.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.61841 9.65735L6.39264 13.1152L6.33321 11.3974L4.61841 9.65735Z"
      fill="#E27525"
      stroke="#E27525"
      strokeWidth="0.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.6745 11.3974L13.6077 13.1152L15.3819 9.65735L13.6745 11.3974Z"
      fill="#E27525"
      stroke="#E27525"
      strokeWidth="0.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.84954 9.84985L8.45605 12.0194L8.95343 14.5813L9.0648 11.2049L8.84954 9.84985Z"
      fill="#E27525"
      stroke="#E27525"
      strokeWidth="0.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.1505 9.84985L10.9426 11.1975L11.0466 14.5813L11.5439 12.0194L11.1505 9.84985Z"
      fill="#E27525"
      stroke="#E27525"
      strokeWidth="0.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.5442 12.0193L11.0469 14.5812L11.4032 14.833L13.608 13.1152L13.6748 11.3973L11.5442 12.0193Z"
      fill="#F5841F"
      stroke="#F5841F"
      strokeWidth="0.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.33276 11.3973L6.39219 13.1152L8.59693 14.833L8.95328 14.5812L8.45591 12.0193L6.33276 11.3973Z"
      fill="#F5841F"
      stroke="#F5841F"
      strokeWidth="0.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.5887 17.1952L11.6109 16.4918L11.4179 16.3288H8.58217L8.39657 16.4918L8.41143 17.1952L6.03589 16.0697L6.86732 16.7509L8.55246 17.9134H11.4402L13.1327 16.7509L13.9567 16.0697L11.5887 17.1952Z"
      fill="#C0AC9D"
      stroke="#C0AC9D"
      strokeWidth="0.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.4032 14.8331L11.0469 14.5814H8.95347L8.59719 14.8331L8.39673 16.4918L8.58233 16.3289H11.4181L11.6111 16.4918L11.4032 14.8331Z"
      fill="#161616"
      stroke="#161616"
      strokeWidth="0.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18.975 6.05894L19.606 2.99353L18.6558 0.142822L11.4031 5.51105L14.1943 7.86562L18.1362 9.01334L19.0047 7.99894L18.6262 7.72494L19.2274 7.17705L18.7672 6.82162L19.3685 6.36254L18.975 6.05894Z"
      fill="#763E1A"
      stroke="#763E1A"
      strokeWidth="0.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M0.394043 2.99353L1.03247 6.05894L0.624174 6.36254L1.2329 6.82162L0.772643 7.17705L1.37395 7.72494L0.995346 7.99894L1.8639 9.01334L5.80581 7.86562L8.59701 5.51105L1.34425 0.142822L0.394043 2.99353Z"
      fill="#763E1A"
      stroke="#763E1A"
      strokeWidth="0.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18.136 9.01314L14.1941 7.86548L15.3819 9.65736L13.6077 13.1152L15.9535 13.0856H19.4574L18.136 9.01314Z"
      fill="#F5841F"
      stroke="#F5841F"
      strokeWidth="0.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.80563 7.86548L1.86376 9.01314L0.549805 13.0856H4.04628L6.39209 13.1152L4.61789 9.65736L5.80563 7.86548Z"
      fill="#F5841F"
      stroke="#F5841F"
      strokeWidth="0.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.1508 9.85011L11.4032 5.51108L12.5464 2.42346H7.45386L8.59706 5.51108L8.84946 9.85011L8.94597 11.2125L8.9534 14.5815H11.0468L11.0543 11.2125L11.1508 9.85011Z"
      fill="#F5841F"
      stroke="#F5841F"
      strokeWidth="0.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
