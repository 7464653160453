import { Audiotrack, PlayArrow } from '@mui/icons-material';
import { Avatar, Container, Dialog, DialogContent } from '@mui/material';
import { grey } from '@mui/material/colors';
import { makeStyles } from '@mui/styles';
import { MouseEvent, ReactElement, useCallback, useState } from 'react';

type Props = {
  file: File;
  size?: number;
};

const useStyles = makeStyles(() => ({
  dialogContent: {
    padding: 0,
  },
  previewRoot: {
    '& img': {
      maxWidth: '100%',
      height: 'auto',
    },
  },
}));

export const MediaFileThumbnail = ({
  file,
  size = 64,
}: Props): ReactElement => {
  const styles = useStyles();
  const { type } = file;
  const [preview, setPreview] = useState(false);
  const sx = { width: size, height: size };
  const sxIcon = { ...sx, background: grey[200] };
  const url = URL.createObjectURL(file);
  const imagePreview = useCallback((event: MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    setPreview(true);
  }, []);
  if (type.startsWith('image/')) {
    return (
      <>
        <a
          onClick={imagePreview}
          href={url}
          target="_blank"
          rel="noopener noreferrer"
        >
          <Avatar sx={sx} variant="rounded" src={URL.createObjectURL(file)} />
        </a>
        <Dialog
          onClose={() => {
            setPreview(false);
          }}
          maxWidth="lg"
          open={preview}
        >
          <DialogContent classes={{ root: styles.dialogContent }}>
            <Container disableGutters classes={{ root: styles.previewRoot }}>
              <img alt={file.name} src={URL.createObjectURL(file)} />
            </Container>
          </DialogContent>
        </Dialog>
      </>
    );
  }

  if (type.startsWith('video/')) {
    return (
      <a href={url} target="_blank" rel="noopener noreferrer">
        <Avatar sx={sxIcon} variant="rounded">
          <PlayArrow />
        </Avatar>
      </a>
    );
  }

  if (type.startsWith('audio/')) {
    return (
      <a href={url} target="_blank" rel="noopener noreferrer">
        <Avatar sx={sxIcon} variant="rounded">
          <Audiotrack />
        </Avatar>
      </a>
    );
  }

  return <></>;
};
