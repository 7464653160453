const numberLocale = 'en-US';

// eslint-disable-next-line @typescript-eslint/unbound-method
export const formatNumber = new Intl.NumberFormat(numberLocale).format;

// eslint-disable-next-line @typescript-eslint/unbound-method
export const formatPercent = new Intl.NumberFormat(numberLocale, {
  style: 'percent',
  maximumFractionDigits: 2,
  useGrouping: false,
}).format;

export const formatError = (error: unknown): string => {
  if (error instanceof Error) {
    return error.message;
  }

  if (typeof error === 'string') {
    return error;
  }

  // @ts-expect-error: error properties are unknown
  const message = error?.data?.message || error?.message;

  if (message) {
    return String(message);
  }

  return JSON.stringify(error);
};
