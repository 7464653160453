import { LoadingButton } from '@mui/lab';
import { Typography } from '@mui/material';
import { useCallback, useContext, useMemo, useState } from 'react';
import { useForm } from 'react-final-form';

import { FileDropzoneField } from '~/components/form/fields/file-uploader/file-dropzone-field';
import { HelpPopover } from '~/components/icons/help-popover';
import { FlexBox } from '~/components/layout/flex-box';
import { AppContext } from '~/context/app-context';
import { ItemLayoutContext } from '~/context/item-layout-context';
import { AssetContentView } from '~/routes/wallet/asset-content-view';
import { useAssetUploadProgress } from '~/routes/wallet/asset-upload-context';
import { getAssetContentFromDdc } from '~/services/assets.service';
import { AssetDdcContentType } from '~/types/asset-ddc';

type Props = {
  assetIndex: number;
  cid?: string;
  contentUrl?: Blob;
  fileFieldName: string;
  isOwner?: boolean;
  title: string;
  type: AssetDdcContentType;
};

export const AssetContent = ({
  assetIndex,
  cid,
  type,
  title,
  contentUrl,
  fileFieldName,
  isOwner = false,
}: Props) => {
  const [isLoadingContent, setIsLoadingContent] = useState(false);
  const { mutators } = useForm();
  const { nft } = useContext(ItemLayoutContext);
  const uploadProgress = useAssetUploadProgress(assetIndex);
  const { userPubKey } = useContext(AppContext);
  const { minter, supply, quantity } = nft;
  // in case supply != minter quantity it means that some copies were transferred, so for now let's enable buttons in such a case. If a wallet doesn't have permissions it just won't be rendered.
  const isShowButtonEnabled = isOwner || supply !== quantity;

  const showContentHandler = useCallback(() => {
    if (!cid) {
      return;
    }

    setIsLoadingContent(true);
    getAssetContentFromDdc({
      minter,
      cid,
      owner: userPubKey,
    })
      .then((blob) => {
        mutators.updateAssetContent(assetIndex, blob);
      })
      .finally(() => {
        setIsLoadingContent(false);
      });
  }, [assetIndex, cid, minter, mutators, userPubKey]);

  const showAssetButtonLabel = useMemo(() => {
    switch (type) {
      case 'image/jpeg':
      case 'image/png':
        return 'Show';
      case 'audio/mp4':
        return 'Listen';
      case 'video/mp4':
        return 'Watch';
      default:
        return null;
    }
  }, [type]);

  if (cid) {
    if (contentUrl) {
      return (
        <AssetContentView contentUrl={contentUrl} type={type} title={title} />
      );
    }

    return (
      <LoadingButton
        loading={isLoadingContent}
        disabled={!isShowButtonEnabled}
        onClick={showContentHandler}
        color="primary"
        size="large"
        variant="outlined"
      >
        {showAssetButtonLabel}
      </LoadingButton>
    );
  }

  return (
    <>
      <FlexBox>
        <FileDropzoneField
          accept="image/*,video/*,audio/*"
          name={fileFieldName}
          maxFiles={1}
          uploadProgress={uploadProgress}
          helperText="File types supported: JPG, PNG, GIF, SVG, MP4, WEBM, MP3, WAV, OGG, GLB, GLTF."
        />
        <HelpPopover>
          <Typography sx={{ p: 2 }}>Asset file</Typography>
        </HelpPopover>
      </FlexBox>
      <FlexBox>
        <FileDropzoneField
          accept="image/*,video/*,audio/*"
          name={`${fileFieldName}Preview`}
          maxFiles={1}
          helperText="Preview file types supported: JPG, PNG, GIF, SVG, MP4, WEBM, MP3, WAV, OGG, GLB, GLTF."
        />
        <HelpPopover>
          <Typography sx={{ p: 2 }}>Asset file preview</Typography>
        </HelpPopover>
      </FlexBox>
    </>
  );
};
