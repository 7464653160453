import React from 'react';
import { Redirect, useLocation } from 'react-router-dom';

import { useUserPublicKey } from '~/context/app-context';

export const AuthWrapper: React.FC = ({ children }) => {
  const location = useLocation();
  const pubKey = useUserPublicKey();

  if (!pubKey && location.pathname !== '/') {
    return (
      <Redirect
        to={{
          pathname: '/',
          state: {
            from: location.pathname,
          },
        }}
      />
    );
  }

  return <>{children}</>;
};
