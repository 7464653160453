import { Typography } from '@mui/material';
import { FORM_ERROR, FormApi } from 'final-form';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Form } from 'react-final-form';

import { NumberInput } from '~/components/form/fields';
import { HelpPopover } from '~/components/icons/help-popover';
import { FlexBox } from '~/components/layout/flex-box';
import { AppContext } from '~/context/app-context';
import { formatError } from '~/lib/formatters';
import { useMessages } from '~/lib/notificator';
import { TOKEN_TITLE } from '~/lib/utils';
import { withdraw } from '~/services/token.service';
import { getUserBalanceToWithdraw } from '~/services/user.service';

import { SimpleForm } from './simple-form';

type FormValues = {
  amount: number;
};

export const TokenWithdraw = () => {
  const { userPubKey } = useContext(AppContext);
  const { showMessage } = useMessages();

  // Get the balance in Freeport.
  const [balanceText, setBalanceText] = useState<string>('loading…');

  useEffect(() => {
    (async () => {
      const balance = await getUserBalanceToWithdraw(userPubKey);
      setBalanceText(`${balance} ${TOKEN_TITLE}`);
    })();
  }, [userPubKey]);

  const initialValues = useMemo((): FormValues => ({ amount: 0 }), []);

  const submit = useCallback(
    async (values: FormValues, api: FormApi<FormValues, FormValues>) => {
      const { amount } = values;
      try {
        const txHash = await withdraw(amount.toString(), () => {
          showMessage('Your transaction has been confirmed');
        });
        showMessage(
          `Transaction submitted successfully with hash: ${txHash}!`,
          'success'
        );
        api.restart();
        return null;
      } catch (error) {
        showMessage(`Error occurred. ${formatError(error)}`, 'error');
        return { [FORM_ERROR]: formatError(error) };
      }
    },
    [showMessage]
  );

  return (
    <Form onSubmit={submit} initialValues={initialValues}>
      {({ handleSubmit, pristine, submitting, hasValidationErrors }) => (
        <SimpleForm
          submitDisabled={pristine || hasValidationErrors || submitting}
          onSubmit={handleSubmit}
          submitButtonTitle="Withdraw"
          actionInProgress={submitting}
          isValid={() => !hasValidationErrors}
        >
          <FlexBox sx={{ mb: 5 }}>
            <NumberInput
              min={1}
              label="Amount"
              name="amount"
              required
              inputMode="numeric"
              helperText={`Available: ${balanceText}`}
            />
            <HelpPopover>
              <Typography sx={{ p: 2, maxWidth: 360 }}>
                Amount of tokens to withdraw
              </Typography>
            </HelpPopover>
          </FlexBox>
        </SimpleForm>
      )}
    </Form>
  );
};
