import { Card, List, ListItem } from '@mui/material';
import { useContext } from 'react';
import { NavLink } from 'react-router-dom';

import { routes, RoutesEnum } from '~/constants/routes';
import { ZERO_ADDRESS } from '~/constants/web3';
import { ItemLayoutContext } from '~/context/item-layout-context';
import { putUrlParam } from '~/lib/url';
import { Auction } from '~/types/auction';

type Props = { auction: Auction; raised?: boolean };

export const AuctionCard = ({ auction, raised }: Props) => {
  const { auctionId, seller, buyer, priceInUsdCents, endsAt, status } = auction;
  const { nft } = useContext(ItemLayoutContext);
  const { nftId } = nft;

  return (
    <NavLink
      to={putUrlParam(routes[RoutesEnum.NFT_AUCTION].url, {
        nftId,
        auctionId,
      })}
    >
      <Card raised={raised}>
        <List>
          <ListItem>Status: {status}</ListItem>
          <ListItem>Seller: {seller}</ListItem>
          <ListItem>Buyer: {buyer !== ZERO_ADDRESS ? buyer : '---'}</ListItem>
          <ListItem>Price: {priceInUsdCents / 100}</ListItem>
          <ListItem>Ends at: {endsAt}</ListItem>
        </List>
      </Card>
    </NavLink>
  );
};
