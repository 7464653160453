import { BigNumber, ContractTransaction } from 'ethers';

import { ZERO_ADDRESS } from '~/constants/web3';
import { getFreeport } from '~/services/get-freeport';
import { Royalties } from '~/types/royalties';
import { isRoyaltiesRaw } from '~/types/royalties-raw';

import { getCollection } from './get-collection';

export const configureRoyalties = async (
  nftId: string,
  params: Royalties,
  collectionAddress?: string
): Promise<ContractTransaction> => {
  if (collectionAddress) {
    const collection = await getCollection(collectionAddress);
    return collection.setupRoyaltyConfiguration(
      params.primaryRoyaltyAccount,
      params.primaryRoyaltyCut,
      params.primaryRoyaltyMinimum,
      params.secondaryRoyaltyAccount,
      params.secondaryRoyaltyCut,
      params.secondaryRoyaltyMinimum
    );
  }

  const freeport = await getFreeport();
  return freeport.configureRoyalties(
    nftId,
    params.primaryRoyaltyAccount,
    params.primaryRoyaltyCut,
    params.primaryRoyaltyMinimum,
    params.secondaryRoyaltyAccount,
    params.secondaryRoyaltyCut,
    params.secondaryRoyaltyMinimum
  );
};

function validateRoyalties(
  primaryRoyaltyAccount: string,
  primaryRoyaltyCut: BigNumber,
  primaryRoyaltyMinimum: BigNumber,
  secondaryRoyaltyAccount: string,
  secondaryRoyaltyCut: BigNumber,
  secondaryRoyaltyMinimum: BigNumber
) {
  const result = {
    primaryRoyaltyAccount:
      primaryRoyaltyAccount === ZERO_ADDRESS ? '' : primaryRoyaltyAccount,
    primaryRoyaltyCut: primaryRoyaltyCut.toNumber(),
    primaryRoyaltyMinimum: primaryRoyaltyMinimum.toNumber(),
    secondaryRoyaltyAccount:
      secondaryRoyaltyAccount === ZERO_ADDRESS ? '' : secondaryRoyaltyAccount,
    secondaryRoyaltyCut: secondaryRoyaltyCut.toNumber(),
    secondaryRoyaltyMinimum: secondaryRoyaltyMinimum.toNumber(),
  };

  if (!isRoyaltiesRaw(result)) {
    throw new Error(
      'Something went wrong. Response for Royalties is incorrect.'
    );
  }

  return result;
}

async function getCollectionRoyalties(collectionAddress: string) {
  const collection = await getCollection(collectionAddress);
  const {
    primaryRoyaltyAccount,
    primaryRoyaltyCut,
    primaryRoyaltyMinimum,
    secondaryRoyaltyAccount,
    secondaryRoyaltyCut,
    secondaryRoyaltyMinimum,
  } = await collection.getRoyalties();

  return validateRoyalties(
    primaryRoyaltyAccount,
    primaryRoyaltyCut,
    primaryRoyaltyMinimum,
    secondaryRoyaltyAccount,
    secondaryRoyaltyCut,
    secondaryRoyaltyMinimum
  );
}

async function getNftRoyalties(nftId: string) {
  const freeport = await getFreeport();
  const {
    primaryRoyaltyAccount,
    primaryRoyaltyCut,
    primaryRoyaltyMinimum,
    secondaryRoyaltyAccount,
    secondaryRoyaltyCut,
    secondaryRoyaltyMinimum,
  } = await freeport.getRoyalties(nftId);

  return validateRoyalties(
    primaryRoyaltyAccount,
    primaryRoyaltyCut,
    primaryRoyaltyMinimum,
    secondaryRoyaltyAccount,
    secondaryRoyaltyCut,
    secondaryRoyaltyMinimum
  );
}

export const getRoyalties = async (
  nftId: string,
  collectionAddress?: string
): Promise<Royalties> => {
  if (collectionAddress) {
    return getCollectionRoyalties(collectionAddress);
  }

  return getNftRoyalties(nftId);
};
