import { Container, Typography } from '@mui/material';
import { useContext, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import useSWR from 'swr';

import { TokenTransfer } from '~/components/form/token-transfer';
import { PageLayout } from '~/components/layout/page-layout';
import { AppContext } from '~/context/app-context';
import { ItemLayoutContext } from '~/context/item-layout-context';
import { isStringEqual } from '~/lib/utils';
import { getOwnTokens } from '~/services/assets.service';
import { Token } from '~/types/token';

export const Transfer = () => {
  const { nftId } = useParams<{ nftId: string }>();
  const { userPubKey } = useContext(AppContext);
  const { nft } = useContext(ItemLayoutContext);

  const { data } = useSWR<Token[], Error>([userPubKey], getOwnTokens);
  const tokens = useMemo(() => data ?? [], [data]);

  const isMinter = isStringEqual(nft.minter, userPubKey);
  const isOwner = !!tokens.some((token) => isStringEqual(nftId, token.nftId));
  const isReadOnly = !(isMinter || isOwner);

  return (
    <PageLayout title="Safe NFT token transfer">
      <Container maxWidth="sm" sx={{ mb: 5 }}>
        <Typography paragraph sx={{ px: 1, mt: 2, mb: 5 }}>
          Send NFTs to other accounts.
        </Typography>
        <TokenTransfer
          tokenId={nftId}
          collectionAddress={nft.collectionAddress}
          disabled={isReadOnly}
        />
      </Container>
    </PageLayout>
  );
};
