import { CircularProgress } from '@mui/material';
import { abbreviate } from '@pqt/abbreviate';
import { useContext, useEffect, useState } from 'react';

import { AppContext } from '~/context/app-context';
import { useMessages } from '~/lib/notificator';
import { TOKEN_TITLE } from '~/lib/utils';
import {
  getUserBalanceToWithdraw,
  getUserInternalBalance,
} from '~/services/user.service';

export const UserBalance = () => {
  const [userInternalBalance, setUserInternalBalance] = useState<number>(0);
  const [userBalanceToWithdraw, setUserBalanceToWithdraw] = useState<number>(0);
  const [isInternalBalanceLoading, updateInternalBalanceLoading] =
    useState<boolean>(true);
  const [isBalanceToWithdrawLoading, updateBalanceToWithdrawLoading] =
    useState<boolean>(true);
  const { userPubKey } = useContext(AppContext);
  const { showMessage } = useMessages();

  const loadUserBalances = () => {
    if (!userPubKey) {
      return;
    }

    void getUserInternalBalance(userPubKey)
      .then((balance) => {
        setUserInternalBalance(balance);
      })
      .catch((error) => {
        showMessage(`Error occurred. ${String(error)}`, 'error');
      })
      .finally(() => {
        updateInternalBalanceLoading(false);
      });

    void getUserBalanceToWithdraw(userPubKey)
      .then((balance) => {
        setUserBalanceToWithdraw(balance);
      })
      .catch((error) => {
        showMessage(`Error occurred. ${String(error)}`, 'error');
      })
      .finally(() => {
        updateBalanceToWithdrawLoading(false);
      });
  };

  useEffect(
    () => {
      loadUserBalances();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [userPubKey]
  );

  const renderBalance = (
    title: string,
    balance: number,
    isLoading: boolean
  ) => (
    <div>
      {title}:{' '}
      {isLoading ? (
        <CircularProgress size={16} />
      ) : (
        `${abbreviate(balance)} ${TOKEN_TITLE}`
      )}{' '}
    </div>
  );

  return (
    <>
      {renderBalance('Balance', userInternalBalance, isInternalBalanceLoading)}
      {renderBalance(
        'To withdraw',
        userBalanceToWithdraw,
        isBalanceToWithdrawLoading
      )}
    </>
  );
};
