import { ReactElement, useCallback, useEffect, useState } from 'react';
import useSWR from 'swr';

import { UploadItem } from '~/context/uploads/uploads-context';
import { useMessages } from '~/lib/notificator';
import { stringToDataHexString } from '~/lib/utils';
import { getDdcUploadInfo } from '~/services/assets.service';
import { getNftAttachment } from '~/services/get-nft-attachment';

type Props = {
  upload: UploadItem;
};

export const UploadWatcher = ({ upload }: Props): ReactElement => {
  const { showMessage } = useMessages();
  const { title, result, id, nftId } = upload;
  const [completed, setCompleted] = useState(Boolean(result));
  const { data } = useSWR([id], completed ? null : getDdcUploadInfo, {
    refreshInterval: completed ? undefined : 500,
    fallbackData: upload,
  });

  const attachToNft = useCallback(
    async (cid: string) => {
      const attachment = await getNftAttachment();
      const tx = await attachment.collectionManagerAttachToNFT(
        BigInt(nftId),
        stringToDataHexString(cid)
      );
      await tx.wait();
    },
    [nftId]
  );

  useEffect(() => {
    if (data?.failed) {
      setCompleted(true);
      showMessage(`The asset's ${title} uploading failed`, 'error');
    } else if (data?.result) {
      setCompleted(true);
      void attachToNft(data?.result).then(() => {
        showMessage(
          `The asset ${title} has been uploaded and attached to the NFT`,
          'success'
        );
      });
    }
  }, [attachToNft, data, showMessage, title]);

  return <></>;
};
