import { useParams } from 'react-router-dom';

import { ItemLayout } from '~/components/layout/item-layout';
import { Wallet, WalletType } from '~/routes/wallet/wallet';

import { AccountNavigation } from './account-navigation';

export const AccountOwned = () => {
  const { accountId } = useParams<{ accountId: string }>();

  return (
    <ItemLayout navigation={AccountNavigation}>
      <Wallet type={WalletType.OWNED} address={accountId} />
    </ItemLayout>
  );
};
