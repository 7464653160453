import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { Box, Popover, styled } from '@mui/material';
import { MouseEvent, ReactNode, useMemo, useState } from 'react';

let id = 0;
const getId = () => {
  id += 1;
  return id.toString(36);
};

type Props = {
  children: ReactNode;
  className?: string;
};

export const HelpPopover = styled(({ children, className }: Props) => {
  const [anchorElement, setAnchorElement] = useState<SVGSVGElement | null>(
    null
  );
  const open = Boolean(anchorElement);
  const popoverId = `mouse-over-popover-${useMemo(getId, [])}`;

  return (
    <Box className={className} sx={{ display: 'inline-flex' }}>
      <HelpOutlineIcon
        sx={{ opacity: 0.5 }}
        aria-owns={open ? popoverId : undefined}
        aria-haspopup="true"
        onMouseEnter={(event: MouseEvent<SVGSVGElement>) => {
          setAnchorElement(event.currentTarget);
        }}
        onMouseLeave={() => {
          setAnchorElement(null);
        }}
      />
      <Popover
        id={popoverId}
        sx={{
          pointerEvents: 'none',
        }}
        open={open}
        anchorEl={anchorElement}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        onClose={() => {
          setAnchorElement(null);
        }}
        disableRestoreFocus
      >
        {children}
      </Popover>
    </Box>
  );
})``;
