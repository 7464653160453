import {
  ApplicationEnum,
  createMarketplace,
  Deployment,
  getMarketplaceAddress,
  Marketplace,
} from '@cere/freeport-sdk';

import { APPLICATION, CONTRACTS_DEPLOYMENT } from '~/constants/env';
import { getSigner } from '~/services/get-signer';

let marketplace: Marketplace | null = null;

export const getMarketPlace = async (): Promise<Marketplace> => {
  if (marketplace) return marketplace;

  const signer = await getSigner();
  const contractAddress = await getMarketplaceAddress(
    signer.provider,
    CONTRACTS_DEPLOYMENT as Deployment,
    APPLICATION as ApplicationEnum
  );

  marketplace = createMarketplace({ signer, contractAddress });

  return marketplace;
};
