import { isRecord } from '~/types/is-record';

export type AssetDdcContentType =
  | 'image/png'
  | 'image/jpeg'
  | 'video/mp4'
  | 'audio/mp4';

export type AssetDdc = {
  uploadedBy: string;
  contentMetadata: {
    contentType: AssetDdcContentType;
    title: string;
    description: string;
  };
};

export const isAssetDdc = (value: unknown): value is AssetDdc =>
  isRecord(value) &&
  typeof value.uploadedBy === 'string' &&
  value.uploadedBy !== '' &&
  isRecord(value.contentMetadata) &&
  typeof value.contentMetadata.contentType === 'string' &&
  value.contentMetadata.contentType !== '' &&
  typeof value.contentMetadata.title === 'string' &&
  value.contentMetadata.title !== '' &&
  typeof value.contentMetadata.description === 'string' &&
  value.contentMetadata.description !== '';
