import { Box, Stack, Typography } from '@mui/material';

import { formatNumber } from '~/lib/formatters';
import { TOKEN_TITLE } from '~/lib/utils';

import { RoyaltyAccount } from './royalty-account';

type Props = {
  title: string;
  account: string;
  cut: number;
  minimum: number;
};

export const RoyaltyView = ({ title, cut, minimum, account }: Props) => (
  <Stack
    direction="column"
    alignItems="center"
    spacing={2}
    sx={{ width: '100%' }}
  >
    <Box sx={{ mt: 1 }}>
      <Typography variant="h6">{title}</Typography>
    </Box>
    <Typography variant="h4" sx={{ mb: 0.5 }}>
      {formatNumber(cut / 100) ?? 0}%
    </Typography>
    <Typography variant="body2" sx={{ opacity: 0.72 }}>
      min. {minimum} {TOKEN_TITLE}
    </Typography>
    <RoyaltyAccount account={account} />
  </Stack>
);
