import {
  Box,
  Card,
  CardContent,
  Container,
  Grid,
  Stack,
  Typography,
} from '@mui/material';

import ERC20Diagram from '~/assets/erc20-diagram.jpg';
import { TokenDeposit } from '~/components/form/token-deposit';
import { TokenDevMint } from '~/components/form/token-dev-mint';
import { TokenTransfer } from '~/components/form/token-transfer';
import { TokenWithdraw } from '~/components/form/token-withdraw';
import { PageLayout } from '~/components/layout/page-layout';
import { TOKEN_ID } from '~/constants/web3';
import { TOKEN_TITLE } from '~/lib/utils';
import { isTestERC20 } from '~/services/get-erc20';

export const TransferCere = () => (
  <PageLayout title={`${TOKEN_TITLE} Deposit`}>
    <Box sx={{ textAlign: 'center', mt: 1, mb: 5 }}>
      <Grid container columns={12}>
        <Grid item lg={6} md={8} sx={{ mx: 'auto' }}>
          <Box
            component="img"
            sx={{ maxHeight: 160 }}
            alt="ERC-20 Diagram"
            src={ERC20Diagram}
          />
        </Grid>
      </Grid>
    </Box>

    <Container maxWidth="sm" sx={{ mb: 5 }}>
      <Stack spacing={3} sx={{ mb: 3 }}>
        <Card>
          <CardContent>
            <Typography paragraph sx={{ px: 1, mt: 2, mb: 5 }}>
              Deposit {TOKEN_TITLE} into Freeport.
            </Typography>
            <TokenDeposit />
          </CardContent>
        </Card>

        <Card>
          <CardContent>
            <Typography paragraph sx={{ px: 1, mt: 2, mb: 5 }}>
              Withdraw {TOKEN_TITLE} from Freeport.
            </Typography>
            <TokenWithdraw />
          </CardContent>
        </Card>

        <Card>
          <CardContent>
            <Typography paragraph sx={{ px: 1, mt: 2, mb: 5 }}>
              Transfer deposited {TOKEN_TITLE} between accounts in Freeport.
            </Typography>
            <TokenTransfer tokenId={TOKEN_ID} />
          </CardContent>
        </Card>

        {isTestERC20 && (
          <Card>
            <CardContent>
              <Typography paragraph sx={{ px: 1, mt: 2, mb: 5 }}>
                Mint TEST-{TOKEN_TITLE} tokens in a development environment.
              </Typography>
              <TokenDevMint />
            </CardContent>
          </Card>
        )}
      </Stack>
    </Container>
  </PageLayout>
);
