import {
  ApplicationEnum,
  Auction,
  createAuction,
  Deployment,
  getAuctionAddress,
} from '@cere/freeport-sdk';

import { APPLICATION, CONTRACTS_DEPLOYMENT } from '~/constants/env';
import { getSigner } from '~/services/get-signer';

let auction: Auction | null = null;

export const getAuctionContract = async (): Promise<Auction> => {
  if (auction) return auction;

  const signer = await getSigner();
  const contractAddress = await getAuctionAddress(
    signer.provider,
    CONTRACTS_DEPLOYMENT as Deployment,
    APPLICATION as ApplicationEnum
  );

  auction = createAuction({ signer, contractAddress });

  return auction;
};
