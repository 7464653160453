/* eslint-disable unicorn/prefer-spread */
import styled from '@emotion/styled';
import { Add, Close as CloseIcon } from '@mui/icons-material';
import {
  Alert,
  Button,
  Container,
  Divider,
  Grid,
  IconButton,
  Typography,
} from '@mui/material';
import {
  FORM_ERROR,
  FormApi,
  SubmissionErrors,
  ValidationErrors,
} from 'final-form';
import arrayMutators from 'final-form-arrays';
import { Fragment, useCallback, useMemo, useState } from 'react';
import { Form } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';

import { Checkbox, Input, NumberInput } from '~/components/form/fields';
import { SimpleForm } from '~/components/form/simple-form';
import { HelpPopover } from '~/components/icons/help-popover';
import { FlexBox } from '~/components/layout/flex-box';
import { PageLayout } from '~/components/layout/page-layout';
import { SERVICE_ACCOUNT, SERVICE_FEE } from '~/constants/env';
import { formatError } from '~/lib/formatters';
import { useMessages } from '~/lib/notificator';
import { isValidFractions, percentToFraction } from '~/lib/utils';
import { createJointAccount } from '~/services/joint-accounts.service';

const MIN_FRACTION_VALUE = 1;
const MAX_FRACTION_VALUE = 10_000;

const FormRow = styled.div`
  position: relative;
  display: grid;
  grid-row-gap: 1.25rem;
  padding: 1rem 0 2rem;
`;

const CloseButton = styled.div`
  position: absolute;
  top: -1rem;
  right: -0.5rem;
`;

type OwnerFraction = {
  owner: string;
  fraction: number;
};

type FormStateValues = {
  serviceFee: boolean;
  fractionItems: OwnerFraction[];
};

const getInitialFormState = (): FormStateValues => ({
  serviceFee: true,
  fractionItems: [],
});

export const CreateJointAccount = () => {
  const [formState, setFormState] = useState<FormStateValues>(
    getInitialFormState()
  );
  const { showMessage } = useMessages();

  const formMutators = useMemo(() => ({ ...arrayMutators }), []);

  const formValidate = useCallback(
    ({ fractionItems, serviceFee }: FormStateValues): ValidationErrors => {
      if (fractionItems.length === 0) {
        return { [FORM_ERROR]: 'No accounts has been provided' };
      }

      const fractions = fractionItems
        .map(({ fraction }) => Number(fraction))
        .concat(serviceFee ? Number(SERVICE_FEE) : []);

      if (!isValidFractions(fractions)) {
        return {
          [FORM_ERROR]: `Sum of all fractions: ${fractions.join(
            ' + '
          )} not equal 100%`,
        };
      }

      return undefined;
    },
    []
  );

  const submit = useCallback(
    async (
      { serviceFee, fractionItems }: FormStateValues,
      form: FormApi<FormStateValues>
    ): Promise<SubmissionErrors | void> => {
      const { valid, error } = form.getState();
      if (!valid) {
        return { [FORM_ERROR]: error };
      }

      const owners: string[] = fractionItems.map(({ owner }) => owner);
      const fractions: number[] = fractionItems.map(
        ({ fraction }) => (fraction / 100) * MAX_FRACTION_VALUE
      );

      if (serviceFee && SERVICE_FEE && SERVICE_ACCOUNT) {
        owners.push(SERVICE_ACCOUNT);
        fractions.push(percentToFraction(SERVICE_FEE));
      }

      try {
        const txHash = await createJointAccount(owners, fractions);
        showMessage(
          `Transaction submitted successfully with hash: ${txHash}!`,
          'success'
        );
      } catch (txError: unknown) {
        return {
          [FORM_ERROR]: `Error occurred. ${formatError(txError)}`,
        };
      }

      setFormState(getInitialFormState());
      return undefined;
    },
    [showMessage]
  );

  return (
    <PageLayout title="Create Joint Account">
      <Container component="section" sx={{ mb: 5 }}>
        <Grid container columns={12}>
          <Grid item lg={6} md={8} sx={{ mx: 'auto' }}>
            <Form
              validate={formValidate}
              onSubmit={submit}
              initialValues={formState}
              mutators={formMutators}
            >
              {({
                pristine,
                submitFailed,
                submitError,
                handleSubmit,
                submitting,
                error,
                form: { mutators, getState },
              }) => (
                <SimpleForm
                  onSubmit={handleSubmit}
                  submitDisabled={pristine}
                  submitButtonTitle="Create"
                  actionInProgress={submitting}
                >
                  <FieldArray name="fractionItems">
                    {({ fields }) => (
                      <>
                        <Typography sx={{ px: 1, mt: 3 }} paragraph>
                          In case you want to split royalties between multiple
                          parties you can create a joint account. Add the
                          Polygon or Ethereum wallet addresses of any party who
                          should receive a share of the NFT royalties. Decide
                          per party what percentage of total account they will
                          receive.
                        </Typography>
                        <Typography sx={{ px: 1 }} paragraph>
                          Specify an account owner for each party and their
                          share or the revenues. The size of a share is given as
                          a fraction in basis points (1% of 1%). The sum of
                          share fractions must equal 100%.
                        </Typography>
                        {submitFailed && (error || submitError) && (
                          <Alert severity="error">{error || submitError}</Alert>
                        )}
                        <FlexBox
                          sx={{
                            justifyContent: 'center',
                            mt: 2,
                            my: 4,
                          }}
                        >
                          <Button
                            title="Add owner"
                            variant="outlined"
                            startIcon={<Add />}
                            onClick={() => {
                              mutators.push('fractionItems', {
                                owner: '',
                                fraction: 0,
                              });
                            }}
                          >
                            Add owner
                          </Button>
                        </FlexBox>
                        {fields.map((name, index) => (
                          <Fragment key={name}>
                            <FormRow>
                              <FlexBox>
                                <Input
                                  name={`${name}.owner`}
                                  required
                                  label={`Owner #${index + 1}`}
                                />
                                <HelpPopover>
                                  <Typography sx={{ m: 2, maxWidth: 250 }}>
                                    An owner may be another Joint Account, or a
                                    smart contract.
                                  </Typography>
                                  <Divider />
                                  <Typography sx={{ m: 2 }}>
                                    Type: Ethereum/Polygon address
                                  </Typography>
                                </HelpPopover>
                              </FlexBox>
                              <FlexBox>
                                <NumberInput
                                  name={`${name}.fraction`}
                                  required
                                  min={
                                    (MIN_FRACTION_VALUE / MAX_FRACTION_VALUE) *
                                    100
                                  }
                                  max={100}
                                  label="Share"
                                  inputMode="decimal"
                                  suffix="%"
                                />
                                <HelpPopover>
                                  <Typography sx={{ m: 2, maxWidth: 250 }}>
                                    The share should be configured in percentage
                                    out of 100%, being a number from 1 to 100.
                                  </Typography>
                                </HelpPopover>
                              </FlexBox>
                              <CloseButton>
                                <IconButton
                                  title="Remove"
                                  color="secondary"
                                  onClick={() => fields.remove(index)}
                                >
                                  <CloseIcon />
                                </IconButton>
                              </CloseButton>
                            </FormRow>
                          </Fragment>
                        ))}
                      </>
                    )}
                  </FieldArray>
                  <FlexBox sx={{ gap: 0, mb: 5 }}>
                    <Checkbox
                      disabled={getState().values.fractionItems.length === 0}
                      name="serviceFee"
                      label={`Include service fee (${SERVICE_FEE}%)`}
                    />
                    <HelpPopover>
                      <Typography sx={{ p: 2, maxWidth: 250 }}>
                        Add a DDC service account with the fixed fraction
                      </Typography>
                    </HelpPopover>
                  </FlexBox>
                </SimpleForm>
              )}
            </Form>
          </Grid>
        </Grid>
      </Container>
    </PageLayout>
  );
};
