import { ReactNode, useCallback, useMemo, useState } from 'react';

import { AppContextType } from '~/context/types';
import { AnalyticService } from '~/services/analytics/analitycs-service';
import { SegmentProvider } from '~/services/analytics/segment-provider';

import { AppContext } from './app-context';

const USER_PUB_KEY = 'USER_PUB_KEY';
const emptyUserPubKey = '';

type Props = {
  children: ReactNode;
};

export const AppProvider = ({ children }: Props) => {
  const [userPubKey, setUserPubKey] = useState<string>(
    (window.localStorage.getItem(USER_PUB_KEY) ?? emptyUserPubKey).toLowerCase()
  );

  const saveUserPubKey = useCallback(
    (newUserPubKey: string) => {
      const pubKey = newUserPubKey.toLowerCase();
      window.localStorage.setItem(USER_PUB_KEY, pubKey);
      setUserPubKey(pubKey);
    },
    [setUserPubKey]
  );

  const context: AppContextType = useMemo(
    () => ({
      userPubKey,
      saveUserPubKey,
      analyticsService: new AnalyticService([new SegmentProvider()]),
    }),
    [userPubKey, saveUserPubKey]
  );

  return <AppContext.Provider value={context}>{children}</AppContext.Provider>;
};
