import { InputAdornment, TextField } from '@mui/material';
import React from 'react';
import { FieldInputProps, FieldMetaState } from 'react-final-form';

import { TextFieldType } from '~/components/form/fields/types';

type Props = {
  input: FieldInputProps<
    string | number,
    HTMLInputElement | HTMLTextAreaElement
  >;
  meta: FieldMetaState<string | number>;
  disabled?: boolean;
  readOnly?: boolean;
  helperText?: string;
  label?: string;
  size: 'small' | 'medium';
  rows?: number;
  multiline: boolean;
  type?: TextFieldType | 'number';
  inputMode?: 'numeric' | 'decimal';
  onFocus?: React.FocusEventHandler<HTMLInputElement>;
  suffix?: string;
  prefix?: string;
};

/* eslint-disable react/jsx-no-duplicate-props */
export const TextInput = ({
  input,
  type = 'text',
  meta,
  disabled,
  helperText,
  readOnly,
  label,
  size,
  rows = 3,
  multiline,
  inputMode,
  onFocus,
  suffix,
  prefix,
}: Props) => {
  const helpText: unknown = meta.touched
    ? meta.error || helperText
    : helperText;

  const handleFocus: React.FocusEventHandler<HTMLInputElement> = (event) => {
    if (onFocus) {
      onFocus(event);
    }

    input.onFocus(event);
  };

  return (
    <TextField
      id={input.name}
      color="primary"
      disabled={disabled}
      error={meta.touched && meta.invalid}
      fullWidth
      helperText={helpText ? String(helpText) : ''}
      inputProps={{ readOnly, inputMode }}
      InputProps={{
        endAdornment: suffix ? (
          <InputAdornment position="end">{suffix}</InputAdornment>
        ) : undefined,
        startAdornment: prefix ? (
          <InputAdornment position="start">{prefix}</InputAdornment>
        ) : undefined,
      }}
      label={label}
      onBlur={input.onBlur}
      onChange={input.onChange}
      onFocus={handleFocus}
      size={size}
      type={type}
      multiline={multiline}
      rows={rows}
      value={input.value}
      variant="outlined"
    />
  );
};
