import { Add as AddIcon } from '@mui/icons-material';
import { Box, Button, Container } from '@mui/material';
import { FormApi, getIn, MutableState, Mutator, Tools } from 'final-form';
import arrayMutators from 'final-form-arrays';
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Form } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';

import { SimpleForm } from '~/components/form/simple-form';
import { PageLayout } from '~/components/layout/page-layout';
import { useFileUpload } from '~/context/file-upload-context';
import { ItemLayoutContext } from '~/context/item-layout-context';
import { formatError } from '~/lib/formatters';
import { useMessages } from '~/lib/notificator';
import { stringToDataHexString } from '~/lib/utils';
import {
  useClearAssetUploadProgress,
  useSetAssetUploadProgress,
} from '~/routes/wallet/asset-upload-context';
import { getNftAssets } from '~/services/assets.service';
import { getNftAttachment } from '~/services/get-nft-attachment';
import { AssetDdc } from '~/types/asset-ddc';
import { RequiredSome } from '~/types/required-some';

import { AssetCid } from './asset-cid';
import { AssetDetails } from './types';

type AssetsForm = { assets: AssetDetails[] };

type Props = {
  isOwner: boolean;
  saveButtonTitle?: string;
};

const getEmptyAsset = (cid?: string): AssetDetails => ({
  cid,
  asset: {
    uploadedBy: '',
    contentMetadata: {
      contentType: 'image/png',
      title: '',
      description: '',
    },
  },
});

const ASSETS_POLLING_INTERVAL = 2000;

// ToDo: Rewrite this view completely
export const AssetsList = ({ isOwner, saveButtonTitle = 'Save' }: Props) => {
  const [assets, setAssets] = useState<string[]>([]);
  const [isFetchLoading, setFetchLoading] = useState<boolean>(true);
  const { nft } = useContext(ItemLayoutContext);
  const { nftId, collectionAddress } = nft;
  const upload = useFileUpload();
  const { showMessage } = useMessages();
  const setUploadProgress = useSetAssetUploadProgress();
  const clearUploadProgress = useClearAssetUploadProgress();
  const intervalId = useRef(0);

  useEffect(() => {
    void getNftAssets(nftId)
      .then(setAssets)
      .catch((error) => {
        showMessage(`Error occurred. ${formatError(error)}`, 'error');
      })
      .finally(() => {
        setFetchLoading(false);
      });
  }, [nftId, showMessage]);

  useEffect(
    () => () => {
      if (isFetchLoading && intervalId.current) {
        clearInterval(Number(intervalId.current));
        intervalId.current = 0;
      }
    },
    [isFetchLoading]
  );

  const startNewAssetsPolling = useCallback(async () => {
    setFetchLoading(true);
    const previousAssets = [...assets];
    intervalId.current = Number(
      setInterval(async () => {
        try {
          const cids = await getNftAssets(nftId);
          if (cids.length !== previousAssets.length) {
            setAssets(cids);
            setFetchLoading(false);
            clearInterval(Number(intervalId.current));
          }
        } catch (error) {
          showMessage(`Error occurred. ${formatError(error)}`, 'error');
          clearInterval(Number(intervalId.current));
          setFetchLoading(false);
        }
      }, ASSETS_POLLING_INTERVAL)
    );
  }, [assets, nftId, showMessage]);

  const assetContentUrlMutator = useCallback(
    (
      [index, contentUrl]: [number, string],
      state: MutableState<AssetsForm>,
      { changeValue }: Tools<AssetsForm>
    ) => {
      changeValue(state, `assets[${index}].contentUrl`, () => contentUrl);
    },
    []
  );

  const assetContentMutator = useCallback(
    (
      [index, assetDetails]: [number, AssetDdc],
      state: MutableState<AssetsForm>,
      { changeValue }: Tools<AssetsForm>
    ) => {
      changeValue(state, `assets[${index}].asset`, () => assetDetails);
    },
    []
  );

  const formMutators: Record<
    string,
    Mutator<AssetsForm, Partial<AssetsForm>>
  > = useMemo(
    () => ({
      ...arrayMutators,
      updateAssetContent: assetContentUrlMutator,
      updateAsset: assetContentMutator,
    }),
    [assetContentMutator, assetContentUrlMutator]
  );

  const attachToNft = useCallback(
    async (cid: string, collection?: string) => {
      if (collection) {
        const contract = await getNftAttachment();
        const tx = await contract.collectionManagerAttachToNFT(
          BigInt(nftId),
          stringToDataHexString(cid)
        );
        await tx.wait();
      } else {
        const contract = await getNftAttachment();
        const tx = await contract.minterAttachToNFT(
          BigInt(nftId),
          stringToDataHexString(cid)
        );
        await tx.wait();
      }
    },
    [nftId]
  );

  const submit = useCallback(
    async (
      values: AssetsForm,
      form: FormApi<AssetsForm, Partial<AssetsForm>>
    ) => {
      const existingAssets = values.assets.filter((asset) => !!asset.cid);
      const newAssets = values.assets.filter(
        (asset) => !asset.cid && asset.files
      ) as Array<RequiredSome<AssetDetails, 'files' | 'filesPreview'>>;
      if (newAssets.length === 0) {
        return;
      }

      try {
        for (const [index, asset] of newAssets.entries()) {
          const {
            files,
            filesPreview,
            asset: { contentMetadata },
          } = asset;
          const { title, description } = contentMetadata;
          // eslint-disable-next-line no-await-in-loop
          const cid = await upload(
            {
              title,
              description,
              assetFile: files[0],
              // ToDo: make this field optional for the back-end and remove it here then
              previewFile: filesPreview[0],
            },
            (progress) => {
              setUploadProgress(existingAssets.length + index, progress);
            }
          );
          // eslint-disable-next-line no-await-in-loop
          await attachToNft(cid, collectionAddress);
        }

        showMessage(`Data saved successfully!`, 'success');
        await startNewAssetsPolling();
      } catch (error) {
        showMessage(`Failed to save data. ${formatError(error)}`, 'error');
      } finally {
        clearUploadProgress();
        form.reset();
      }
    },
    [
      showMessage,
      startNewAssetsPolling,
      upload,
      attachToNft,
      collectionAddress,
      setUploadProgress,
      clearUploadProgress,
    ]
  );

  const initialValues = useMemo<{ assets: AssetDetails[] }>(
    () => ({
      assets: assets.map((cid) => getEmptyAsset(cid)),
    }),
    [assets]
  );

  return (
    <PageLayout title="Assets" isLoading={isFetchLoading}>
      <Container sx={{ mb: 5 }} maxWidth="sm">
        <Form
          initialValues={initialValues}
          onSubmit={submit}
          mutators={formMutators}
        >
          {({
            submitting,
            handleSubmit,
            valid,
            pristine,
            form: { getState, mutators },
          }) => (
            <SimpleForm
              onSubmit={handleSubmit}
              submitDisabled={pristine}
              submitButtonTitle={saveButtonTitle}
              isValid={() => valid}
              actionInProgress={submitting}
              hideSubmit={!isOwner}
            >
              <>
                <FieldArray name="assets">
                  {({ fields }) =>
                    fields.map((name, index) => {
                      const currentAsset: AssetDetails = getIn(
                        getState().values,
                        `${name}`
                      ) as AssetDetails;
                      return (
                        <AssetCid
                          key={name}
                          assetDetails={currentAsset}
                          index={index}
                          name={name}
                        />
                      );
                    })
                  }
                </FieldArray>
                {isOwner && (
                  <Box
                    sx={{
                      mt: 2,
                      mb: 4,
                      display: 'flex',
                      justifyContent: 'center',
                    }}
                  >
                    <Button
                      title="Add asset"
                      color="primary"
                      variant="outlined"
                      startIcon={<AddIcon />}
                      onClick={() => {
                        mutators.push('assets', getEmptyAsset());
                      }}
                    >
                      Add asset
                    </Button>
                  </Box>
                )}
              </>
            </SimpleForm>
          )}
        </Form>
      </Container>
    </PageLayout>
  );
};
