import { BigNumber, BigNumberish } from 'ethers';

import { TOKEN_ID } from '~/constants/web3';
import { TOKEN_DECIMALS, tryTransactionTwice } from '~/lib/utils';
import { getERC20 } from '~/services/get-erc20';
import { getFreeport } from '~/services/get-freeport';

import { getCollection } from './get-collection';

const getAmountToSend = (amount: string): BigNumberish =>
  BigNumber.from(amount).mul(TOKEN_DECIMALS);

async function safeTransferFromCollectionContract(
  collectionAddress: string,
  from: string,
  toAccount: string,
  tokenId: string,
  amountToSend: BigNumber,
  onConfirmed: () => void
) {
  const collection = await getCollection(collectionAddress);
  const tx = await collection.safeTransferFrom(
    from,
    toAccount,
    tokenId,
    amountToSend,
    [0]
  );

  void tx.wait().then(() => {
    onConfirmed();
  });

  return tx.hash;
}

async function safeTransferFromFreeportContract(
  from: string,
  toAccount: string,
  tokenId: string,
  amountToSend: BigNumber,
  onConfirmed: () => void
) {
  const freeport = await getFreeport();
  const tx = await freeport.safeTransferFrom(
    from,
    toAccount,
    tokenId,
    amountToSend,
    [0]
  );

  void tx.wait().then(() => {
    onConfirmed();
  });

  return tx.hash;
}

export const safeTransferFrom = async (
  from: string,
  toAccount: string,
  tokenId: string,
  amount: string,
  onConfirmed: () => void,
  collectionAddress?: string
): Promise<string> => {
  let amountToSend = BigNumber.from(amount);

  if (tokenId === TOKEN_ID) {
    amountToSend = amountToSend.mul(TOKEN_DECIMALS);
  }

  if (collectionAddress) {
    return safeTransferFromCollectionContract(
      collectionAddress,
      from,
      toAccount,
      tokenId,
      amountToSend,
      onConfirmed
    );
  }

  return safeTransferFromFreeportContract(
    from,
    toAccount,
    tokenId,
    amountToSend,
    onConfirmed
  );
};

export const deposit = async (
  amount: string,
  onConfirmed: () => void
): Promise<string> => {
  const amountToSend = getAmountToSend(amount);

  const erc20 = await getERC20();
  const freeport = await getFreeport();

  const tx1 = await erc20.approve(freeport.address, amountToSend);

  const tx2 = await tryTransactionTwice(async () =>
    freeport.deposit(amountToSend, { nonce: tx1.nonce + 1 })
  );

  const confirm = async () => {
    await tx1.wait();
    await tx2.wait();
    onConfirmed();
  };

  void confirm();

  return tx2.hash;
};

export const withdraw = async (
  amount: string,
  onConfirmed: () => void
): Promise<string> => {
  const amountToSend = getAmountToSend(amount);

  const freeport = await getFreeport();
  const tx = await freeport.withdraw(amountToSend);

  void tx.wait().then(() => {
    onConfirmed();
  });

  return tx.hash;
};

export const getERC20balance = async (account: string): Promise<number> => {
  const erc20 = await getERC20();
  const amountBN = await erc20.balanceOf(account);
  const amount = amountBN.div(TOKEN_DECIMALS).toNumber();
  return amount;
};

/** Mint test ERC20 tokens in a development environment. */
export const devMint = async (
  toAccount: string,
  amount: string,
  onConfirmed: () => void
): Promise<string> => {
  const amountToSend = getAmountToSend(amount);

  const erc20 = await getERC20();

  const tx = await erc20.mint(toAccount, amountToSend);

  void tx.wait().then(() => {
    onConfirmed();
  });

  return tx.hash;
};
