import {
  ApplicationEnum,
  createFreeport,
  Deployment,
  Freeport,
  getFreeportAddress,
} from '@cere/freeport-sdk';

import { APPLICATION, CONTRACTS_DEPLOYMENT } from '~/constants/env';
import { getSigner } from '~/services/get-signer';

let freeport: Freeport | null = null;

export const getFreeport = async (): Promise<Freeport> => {
  if (freeport) return freeport;

  const signer = await getSigner();
  const contractAddress = await getFreeportAddress(
    signer.provider,
    CONTRACTS_DEPLOYMENT as Deployment,
    APPLICATION as ApplicationEnum
  );

  freeport = createFreeport({ signer, contractAddress });

  return freeport;
};
