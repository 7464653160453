import searchFill from '@iconify/icons-eva/search-fill';
import { Icon } from '@iconify/react';
import { Box, InputAdornment, OutlinedInput } from '@mui/material';
import { ChangeEvent, FormEvent, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { routes, RoutesEnum } from '~/constants/routes';
import { useMessages } from '~/lib/notificator';
import { putUrlParam } from '~/lib/url';
import { isAccountAddress, isNftAddress } from '~/lib/utils';

export const SearchBox = () => {
  const [searchQuery, setSearchQuery] = useState<string>('');
  const history = useHistory();
  const { showMessage } = useMessages();

  const label = 'Search NFT or account...';

  const onSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.currentTarget.value);
  };

  const onSearchSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const query = searchQuery;
    setSearchQuery('');

    if (isAccountAddress(query)) {
      history.push(
        putUrlParam(routes[RoutesEnum.ACCOUNT_MINTED].url, {
          accountId: query,
        })
      );
      return;
    }

    if (isNftAddress(query)) {
      history.push(
        putUrlParam(routes[RoutesEnum.NFT_ASSETS].url, { nftId: query })
      );
      return;
    }

    showMessage(
      `In order to proceed, you need to enter correct Account address or NFT ID`,
      'error'
    );
  };

  return (
    <Box
      component="form"
      onSubmit={onSearchSubmit}
      sx={{
        display: 'flex',
        flexGrow: 1,
        justifyContent: 'center',
        marginTop: '-4px',
      }}
    >
      <Box
        component={OutlinedInput}
        sx={{
          width: '100%',
          minWidth: '140px',
          marginLeft: 2,
          marginRight: 2,
          maxWidth: 784,
          marginBottom: '-5px',
          '& > .MuiOutlinedInput-input': {
            padding: '8px 14px 8px 2px',
          },
          '& > .MuiOutlinedInput-notchedOutline': {
            borderWidth: `1px !important`,
            borderColor: (theme) => `${theme.palette.grey[300]} !important`,
          },
        }}
        placeholder={label}
        aria-label={label}
        aria-describedby="button-addon2"
        startAdornment={
          <InputAdornment position="start">
            <Box
              component={Icon}
              icon={searchFill}
              sx={{
                color: 'text.disabled',
                width: '20px',
                height: '20px',
                mb: '2px',
              }}
            />
          </InputAdornment>
        }
        value={searchQuery}
        onChange={onSearchChange}
      />
    </Box>
  );
};
