import { Box, Button, Container, Grid, Typography } from '@mui/material';
import { useContext, useEffect, useMemo } from 'react';
import { NavLink } from 'react-router-dom';
import useSWR from 'swr';

import { HelpPopover } from '~/components/icons/help-popover';
import { Empty } from '~/components/layout/empty';
import { FlexBox } from '~/components/layout/flex-box';
import { PageLayout } from '~/components/layout/page-layout';
import { routes, RoutesEnum } from '~/constants/routes';
import { AppContext } from '~/context/app-context';
import { formatError } from '~/lib/formatters';
import { useMessages } from '~/lib/notificator';
import { NftCard } from '~/routes/wallet/nft-card';
import { getMintedTokens, getOwnTokens } from '~/services/assets.service';
import { Token } from '~/types/token';

export enum WalletType {
  MINTED = 'MINTED',
  OWNED = 'OWNED',
}

const fetcher = async (
  address: string,
  walletType: WalletType
): Promise<Token[]> => {
  if (walletType === WalletType.MINTED) {
    return getMintedTokens(address);
  }

  if (walletType === WalletType.OWNED) {
    return getOwnTokens(address);
  }

  throw new Error(`WalletType "${walletType}" is not supported`);
};

type PageTitleProps = { walletType: WalletType };

const PageTitle = ({ walletType }: PageTitleProps) => {
  if (walletType === WalletType.MINTED) {
    return (
      <FlexBox>
        <Typography variant="h4">Minted NFTs</Typography>
        <HelpPopover>
          <Typography sx={{ p: 2, maxWidth: 360 }}>
            NFTs that were issued from your account
          </Typography>
        </HelpPopover>
      </FlexBox>
    );
  }

  if (walletType === WalletType.OWNED) {
    return (
      <FlexBox>
        <Typography variant="h4">Owned NFTs</Typography>
        <HelpPopover>
          <Typography sx={{ p: 2, maxWidth: 360 }}>
            NFTs with an available balance greater than zero that you own with
            your account
          </Typography>
        </HelpPopover>
      </FlexBox>
    );
  }

  return <Typography variant="h4">NFTs</Typography>;
};

const PageActions = () => (
  <FlexBox>
    <NavLink to={routes[RoutesEnum.MINT].url}>
      <Button variant="contained">Mint NFT</Button>
    </NavLink>
    <HelpPopover>
      <Typography sx={{ p: 2, maxWidth: 360 }}>Mint new NFT token</Typography>
    </HelpPopover>
  </FlexBox>
);

type WalletProps = {
  type: WalletType;
  address: string;
};

export const Wallet = ({ type, address }: WalletProps) => {
  const { userPubKey } = useContext(AppContext);
  const { showMessage } = useMessages();

  const { data, error: tokensLoadingError } = useSWR<Token[], Error>(
    [address, type],
    fetcher
  );
  const tokens = useMemo(() => data ?? [], [data]);
  const isLoading = useMemo(() => data == null, [data]);

  useEffect(() => {
    if (tokensLoadingError) {
      showMessage(
        `Error occurred. ${formatError(tokensLoadingError)}`,
        'error'
      );
    }
  }, [showMessage, tokensLoadingError]);

  return (
    <PageLayout isLoading={isLoading}>
      <Container sx={{ mb: 5 }}>
        <FlexBox sx={{ mb: 3, ml: 2 }}>
          <Box sx={{ flexGrow: 1 }}>
            <PageTitle walletType={type} />
          </Box>
          {userPubKey === address && (
            <Box sx={{ flexShrink: 1 }}>
              <PageActions />
            </Box>
          )}
        </FlexBox>

        {tokens.length > 0 ? (
          <Grid container spacing={3}>
            {tokens.map((item: Token) => (
              <Grid key={item.nftId} item xs={12} sm={6} md={3}>
                <NftCard nft={item} />
              </Grid>
            ))}
          </Grid>
        ) : (
          <Empty>Create and configure your NFT</Empty>
        )}
      </Container>
    </PageLayout>
  );
};
