import { Redirect, Route, Switch } from 'react-router-dom';

import { routes, RoutesEnum } from '~/constants/routes';

export const Router = () => (
  <Switch>
    {Object.keys(routes).map((routeKey) => {
      const {
        component: Component,
        url,
        exact,
      } = routes[routeKey as RoutesEnum];

      return (
        <Route path={url} key={routeKey} exact={exact}>
          <Component />
        </Route>
      );
    })}
    <Route path="*">
      <Redirect to="/" />
    </Route>
  </Switch>
);
