import { getTokenConfig } from '@cere/freeport-sdk';
import { BytesLike, ContractTransaction, utils } from 'ethers';

import { CONTRACTS_DEPLOYMENT } from '~/constants/env';

export const TOTAL_FRACTIONS = 10_000;

const { symbol, decimals } = getTokenConfig(CONTRACTS_DEPLOYMENT);

export const TOKEN_TITLE = symbol;

export const TOKEN_DECIMALS = Number(`1e${decimals}`);

export const shortenHexAddress = (address: string): string => {
  const start = address.slice(2, 6);
  const end = address.slice(-4);

  return `${start}...${end}`;
};

export const shortenNftId = (nftId: string): string => {
  const start = nftId.slice(0, 5);
  const end = nftId.slice(-5);

  return `${start}...${end}`;
};

export const isAccountAddress = (address: string): boolean =>
  utils.isAddress(address);

export const isNftAddress = (nftId: string): boolean =>
  !!nftId && /^\d+$/.test(nftId);

export const isStringEqual = (string1: string, string2: string): boolean =>
  string1.toLowerCase() === string2.toLowerCase();

export const percentToFraction = (fraction: number): number => fraction * 100;
export const fractionToPercent = (fraction: number): number => fraction / 100;

export const isValidFractions = (fractions: number[]): boolean => {
  let sum = 0;
  for (const fraction of fractions) sum += Number(fraction);

  return sum === 100;
};

export const beautifyNumber = (number: string | number): string =>
  new Intl.NumberFormat(navigator.language || 'de', {
    style: 'decimal',
  }).format(Number(number)) || '';

export const tokensToUnits = (tokens: number): number =>
  tokens * TOKEN_DECIMALS; // Change it here based on USDC decimals.

export const unitsToTokens = (units: number): number => units / TOKEN_DECIMALS;

export const stringToDataHexString = (text: string): BytesLike =>
  `0x${[...text].map((char) => char.charCodeAt(0).toString(16)).join('')}`;

const SLEEP_BETWEEN_TRANSACTIONS_MS = 5000;

export const tryTransactionTwice = async (
  f: () => Promise<ContractTransaction>
): Promise<ContractTransaction> => {
  try {
    return await f();
  } catch {
    // Wait for the RPC server to digest the previous operation.
    await new Promise((resolve) =>
      setTimeout(resolve, SLEEP_BETWEEN_TRANSACTIONS_MS)
    );
    return await f();
  }
};
