/* eslint-disable jsx-a11y/media-has-caption */
import { Stack } from '@mui/material';
import { useMemo } from 'react';

import { AssetDdcContentType } from '~/types/asset-ddc';

type Props = {
  contentUrl: Blob;
  type: AssetDdcContentType;
  title: string;
};

export const AssetContentView = ({ contentUrl, type, title }: Props) => {
  const url = useMemo(() => URL.createObjectURL(contentUrl), [contentUrl]);

  if (type === 'image/png' || type === 'image/jpeg') {
    return (
      <Stack>
        <img alt={title} src={url} />
      </Stack>
    );
  }

  if (type === 'audio/mp4') {
    return (
      <audio title={title} controls autoPlay>
        <source src={url} />
      </audio>
    );
  }

  if (type === 'video/mp4') {
    return (
      <video width="600" controls autoPlay>
        <source src={url} />
      </video>
    );
  }

  console.error(`Unhandled media type ${type}`);
  return <></>;
};
