import { get } from '~/lib/request';
import { Auction, AuctionBid } from '~/types/auction';

type ListOptions = {
  nftId?: string;
  collectionAddress?: string;
  onlyActive?: boolean;
  pageIndex?: number;
  pageSize?: number;
};

const getJson = async (response: Response): Promise<unknown> => response.json();

export const getAuctions = async (options: ListOptions): Promise<Auction[]> => {
  const initQuery = Object.entries(options)
    .filter(([, value]) => value !== undefined)
    .map(([key, value]) => [key, String(value)]);

  const queryParams = new URLSearchParams(initQuery);

  const auctions = await get(`/auction/v1?${String(queryParams)}`).then(
    getJson
  );

  // TODO: Check backend schema
  return auctions as Auction[];
};

type AuctionOptions = { auctionId: string };

export const getAuction = async ({
  auctionId,
}: AuctionOptions): Promise<Auction> => {
  const auction = await get(`/auction/v1/${auctionId}`).then(getJson);

  // TODO: Check backend schema
  return auction as Auction;
};

type AuctionBidsOptions = {
  auctionId: string;
  pageIndex?: number;
  pageSize?: number;
};

export const getAuctionBids = async ({
  auctionId,
  pageIndex,
  pageSize,
}: AuctionBidsOptions): Promise<AuctionBid[]> => {
  const queryParams = new URLSearchParams({ auctionId });

  if (pageIndex !== undefined) {
    queryParams.append('pageIndex', String(pageIndex));
  }

  if (pageIndex !== undefined) {
    queryParams.append('pageSize', String(pageSize));
  }

  const bids = await get(
    `/auction/v1/${String(auctionId)}/bids?${String(queryParams)}`
  ).then(getJson);

  // TODO: Check backend schema
  return bids as AuctionBid[];
};
