import { TOKEN_ID } from '~/constants/web3';
import { TOKEN_DECIMALS } from '~/lib/utils';
import { getERC20 } from '~/services/get-erc20';
import { getFreeport } from '~/services/get-freeport';
import { getSigner } from '~/services/get-signer';
import { AssetType } from '~/types/asset';
import { RelationType } from '~/types/relation-type';

export const getUserBalanceToWithdraw = async (
  userPublicKey: string
): Promise<number> => {
  const freeport = await getFreeport();
  const balance = await freeport.balanceOf(userPublicKey, TOKEN_ID);
  return balance.div(TOKEN_DECIMALS).toNumber();
};

export const getUserInternalBalance = async (
  userPublicKey: string
): Promise<number> => {
  const erc20 = await getERC20();
  const balance = await erc20.balanceOf(userPublicKey);
  return balance.div(TOKEN_DECIMALS).toNumber();
};

type UploadSignatureParams = {
  minter: string;
  title: string;
  description: string;
  type: AssetType;
  relationType?: RelationType;
  relationId: string;
};

export const getUploadAssetSignature = async ({
  minter,
  relationId,
  title,
  description,
  type,
  relationType = 'NFT',
}: UploadSignatureParams): Promise<string> => {
  const parts = [
    'Confirm upload asset:',
    `Minter: ${minter}`,
    `Relation type: ${relationType}`,
    `Relation ID: ${relationId}`,
    `Title: ${title}`,
    `Description: ${description}`,
    `Type: ${type}`,
  ];
  const signer = await getSigner();
  return signer.signMessage(parts.join('\n'));
};

export const getContentSignature = async (address: string): Promise<string> => {
  const parts = ['Confirm identity:', `Address: ${address}`];
  const signer = await getSigner();
  return signer.signMessage(parts.join('\n'));
};
