import { Benefit } from '~/types/benefit';
import { BenefitRaw } from '~/types/benefit-raw';

export const benefitRawToBenefitMapper = (benefit: BenefitRaw): Benefit => ({
  nftId: benefit.nftId,
  saleType: benefit.saleType,
  saleCut: benefit.saleCut / 10_000,
  minimumFee: benefit.minimumFee,
  fraction: benefit.fraction / 10_000,
});
