import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';

export interface AssetUploadContextType {
  progress: Record<number, number>;
  setProgress: (index: number, progress: number) => void;
  clearProgress: () => void;
}

export const AssetUploadContext = createContext<AssetUploadContextType>({
  progress: {},
  setProgress: () => {},
  clearProgress: () => {},
});

export const useAssetUploadProgress = (index: number) => {
  const context = useContext(AssetUploadContext);
  return context.progress[index];
};

export const useSetAssetUploadProgress = () => {
  const context = useContext(AssetUploadContext);
  return context.setProgress;
};

export const useClearAssetUploadProgress = () => {
  const context = useContext(AssetUploadContext);
  return context.clearProgress;
};

export const AssetUploadContextProvider: React.FC = ({ children }) => {
  const [uploadProgress, setUploadProgress] = useState<Record<number, number>>(
    {}
  );

  const setProgress = useCallback((index: number, progress: number) => {
    setUploadProgress((up) => ({ ...up, [index]: progress }));
  }, []);

  const clearProgress = useCallback(() => {
    setUploadProgress({});
  }, [setUploadProgress]);

  const value: AssetUploadContextType = useMemo(
    () => ({
      progress: uploadProgress,
      setProgress,
      clearProgress,
    }),
    [uploadProgress, setProgress, clearProgress]
  );

  return (
    <AssetUploadContext.Provider value={value}>
      {children}
    </AssetUploadContext.Provider>
  );
};
