import {
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  CircularProgress,
  Container,
  Divider,
  Stack,
  Typography,
} from '@mui/material';
import { FormApi } from 'final-form';
import { useCallback, useContext, useEffect, useState } from 'react';
import { Form, FormSpy } from 'react-final-form';
import useSWR from 'swr';

import { Input, NumberInput } from '~/components/form/fields';
import { HelpPopover } from '~/components/icons/help-popover';
import { FlexBox } from '~/components/layout/flex-box';
import { PageLayout } from '~/components/layout/page-layout';
import { AppContext } from '~/context/app-context';
import { ItemLayoutContext } from '~/context/item-layout-context';
import { formatError } from '~/lib/formatters';
import { useMessages } from '~/lib/notificator';
import { beautifyNumber, isStringEqual, tokensToUnits } from '~/lib/utils';
import { getCollection } from '~/services/get-collection';
import { getERC20 } from '~/services/get-erc20';
import { getFreeport } from '~/services/get-freeport';
import { getMarketPlace } from '~/services/get-marketplace';

const fetchOffer = async (
  minter: string,
  nftId: string,
  collectionAddress?: string
): Promise<{ price: number; balance: number }> => {
  if (collectionAddress) {
    const marketplace = await getMarketPlace();
    const collection = await getCollection(collectionAddress);

    const price = await marketplace.getOffer(minter, nftId);
    const balance = await collection.balanceOf(minter, nftId);
    return { price: price.toNumber(), balance: balance.toNumber() };
  }

  const freeport = await getFreeport();
  const price = await freeport.getOffer(minter, nftId);
  const balance = await freeport.balanceOf(minter, nftId);
  return { price: price.toNumber(), balance: balance.toNumber() };
};

export interface OffersProps {
  tokenSymbol: string;
  tokenDecimals: number;
}

export const Offers = ({ tokenSymbol, tokenDecimals }: OffersProps) => {
  const { userPubKey } = useContext(AppContext);
  const { nft } = useContext(ItemLayoutContext);
  const { nftId, minter, collectionAddress } = nft;
  const [userKey, setUserKey] = useState<string>(minter);
  const { showMessage } = useMessages();

  const { data, mutate: mutateOffer } = useSWR(
    [userKey, nftId, collectionAddress],
    fetchOffer
  );
  const nftPrice = data?.price ?? 0;
  const nftBalance = data?.balance ?? 0;

  const fetchOfferDetails = useCallback(() => {
    void mutateOffer();
  }, [mutateOffer]);

  const saveUserAddress = useCallback((values: { address: string }) => {
    setUserKey(values.address);
  }, []);

  const submitOfferSell = useCallback(
    async (
      { tokens }: { tokens: string },
      api: FormApi<{ tokens: string }, { tokens: string }>
    ) => {
      try {
        if (collectionAddress) {
          const marketplace = await getMarketPlace();
          const tx = await marketplace.makeOffer(
            nftId,
            tokensToUnits(Number(tokens)) ?? 0
          );
          await tx.wait();
          fetchOfferDetails();
          api.restart();
        } else {
          const freeport = await getFreeport();
          const tx = await freeport.makeOffer(
            nftId,
            tokensToUnits(Number(tokens)) ?? 0
          );
          await tx.wait();
          fetchOfferDetails();
          api.restart();
        }
      } catch (error) {
        showMessage(`Failed to make offer. ${formatError(error)}`, 'error');
      }
    },
    [collectionAddress, fetchOfferDetails, nftId, showMessage]
  );

  type FormButNft = { amount: string };
  const submitByNft = useCallback(
    async ({ amount }: FormButNft, api: FormApi<FormButNft, FormButNft>) => {
      try {
        const erc20 = await getERC20();
        if (collectionAddress) {
          const marketplace = await getMarketPlace();
          const price = nftPrice ? Number(nftPrice) : 0;
          const approvalTx = await erc20.approve(marketplace.address, price);
          await approvalTx.wait();
          const tx = await marketplace.takeOffer(
            userPubKey,
            userKey,
            nftId,
            price,
            Number(amount) ?? 0
          );
          await tx.wait();
        } else {
          const freeport = await getFreeport();
          const price = nftPrice ? Number(nftPrice) : 0;
          const approvalTx = await erc20.approve(freeport.address, price);
          await approvalTx.wait();
          const tx = await freeport.takeOffer(
            userPubKey,
            userKey,
            nftId,
            price,
            Number(amount) ?? 0
          );
          await tx.wait();
        }

        api.restart();
      } catch (error) {
        showMessage(`Failed to take offer. ${formatError(error)}`, 'error');
      }
    },
    [nftId, nftPrice, showMessage, userKey, userPubKey, collectionAddress]
  );

  useEffect(() => {
    setUserKey(minter);
  }, [minter]);

  return (
    <PageLayout>
      <Container maxWidth="sm" sx={{ mb: 5 }}>
        <Stack spacing={3} sx={{ mb: 3 }}>
          <FlexBox>
            <Typography>Minter: {minter}</Typography>
            <HelpPopover>
              <Typography sx={{ p: 2, maxWidth: 360 }}>
                The account that issued this NFT
              </Typography>
            </HelpPopover>
          </FlexBox>
          <Divider />
          <Form
            onSubmit={saveUserAddress}
            initialValues={{ address: userPubKey }}
          >
            {({ handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <FormSpy
                  subscription={{ values: true }}
                  onChange={({ values }) => handleSubmit(values)}
                />
                <Stack direction="row" spacing={1} alignItems="center">
                  <Input required size="small" name="address" label="Seller" />
                  <Button type="submit">Search</Button>
                  <HelpPopover>
                    <Typography sx={{ p: 2, maxWidth: 360 }}>
                      Search to see whether seller made NFT available for sale
                    </Typography>
                  </HelpPopover>
                </Stack>
              </form>
            )}
          </Form>
        </Stack>
        <Card>
          <CardContent>
            <Stack spacing={3}>
              <Box component={Typography}>NFT balance: {nftBalance}</Box>
              {nftPrice > 0 && (
                <Stack direction="row" alignItems="center" spacing={1}>
                  <Typography>Offer price:</Typography>
                  <Chip
                    label={
                      <Typography sx={{ fontWeight: 500 }}>
                        {beautifyNumber(nftPrice / tokenDecimals)}
                      </Typography>
                    }
                    sx={{ mx: 1 }}
                  />{' '}
                  <Typography>{tokenSymbol}</Typography>
                </Stack>
              )}
              {nftPrice === 0 && (
                <Typography sx={{ opacity: 0.5 }}>No offer yet</Typography>
              )}
              {nftPrice > 0 && nftBalance === 0 && (
                <Typography sx={{ opacity: 0.5 }}>Sold</Typography>
              )}
              {isStringEqual(userKey, userPubKey) && nftBalance > 0 && (
                <Form onSubmit={submitOfferSell} initialValues={{ tokens: '' }}>
                  {({ handleSubmit, invalid, submitting }) => (
                    <form onSubmit={handleSubmit}>
                      <Stack direction="row" spacing={1} alignItems="baseline">
                        <NumberInput
                          required
                          name="tokens"
                          label={`Price in ${tokenSymbol}`}
                          size="small"
                        />
                        <Button
                          sx={{
                            minWidth: '180px',
                            display: 'flex',
                            alignItems: 'center',
                          }}
                          variant="contained"
                          disabled={invalid || submitting}
                          type="submit"
                        >
                          Put for sale
                          {submitting && (
                            <CircularProgress
                              sx={{ marginLeft: 2 }}
                              color="secondary"
                              size={20}
                              thickness={2}
                            />
                          )}
                        </Button>
                        <Box sx={{ position: 'relative', top: '6px' }}>
                          <HelpPopover>
                            <Typography sx={{ m: 2, maxWidth: 400 }}>
                              Make NFTs available for sale for a certain price
                              in USDC per copy. Whenever you choose to make an
                              offer to sell it will apply to all copies. NFTs
                              are available for sale until cancelled. To cancel
                              the sale, make an offer with a price of 0
                            </Typography>
                            <Divider />
                            <Typography sx={{ p: 2, maxWidth: 400 }}>
                              Type a number of {tokenSymbol} without trailing
                              zeros, for one unit of NFT.
                            </Typography>
                          </HelpPopover>
                        </Box>
                      </Stack>
                    </form>
                  )}
                </Form>
              )}
              {userPubKey &&
                !isStringEqual(userKey, userPubKey) &&
                nftBalance > 0 &&
                nftPrice > 0 && (
                  <Form onSubmit={submitByNft}>
                    {({ handleSubmit, submitting, invalid }) => (
                      <form onSubmit={handleSubmit}>
                        <Stack
                          direction="row"
                          spacing={1}
                          alignItems="baseline"
                        >
                          <NumberInput
                            required
                            min={1}
                            max={nftBalance}
                            name="amount"
                            label="NFT amount"
                            size="small"
                          />
                          <Button
                            sx={{
                              minWidth: '180px',
                              display: 'flex',
                              alignItems: 'center',
                            }}
                            variant="contained"
                            disabled={invalid || submitting}
                            color="secondary"
                            type="submit"
                          >
                            Buy
                            {submitting && (
                              <CircularProgress
                                sx={{ marginLeft: 2 }}
                                color="secondary"
                                size={20}
                                thickness={2}
                              />
                            )}
                          </Button>
                          <Box sx={{ position: 'relative', top: '6px' }}>
                            <HelpPopover>
                              <Typography sx={{ p: 2, maxWidth: 360 }}>
                                Accept an offer, paying the price per unit for
                                an amount of NFTs.
                              </Typography>
                              <Typography sx={{ p: 2, maxWidth: 360 }}>
                                Users will probably prefer to buy this NFT from
                                other user-friendly apps powered by Freeport.
                                This will have the same effect as this button.
                              </Typography>
                              <Divider />
                              <Typography sx={{ p: 2, maxWidth: 400 }}>
                                Type: an amount of NFTs to buy
                              </Typography>
                            </HelpPopover>
                          </Box>
                        </Stack>
                      </form>
                    )}
                  </Form>
                )}
            </Stack>
          </CardContent>
        </Card>
      </Container>
    </PageLayout>
  );
};
