import { FileUploadError } from '~/model/errors/file-upload-error';
import { PayloadSizeError } from '~/model/errors/payload-size-error';
import {
  ProgressUpdateCallback,
  UploadApi,
  UploadPayload,
} from '~/model/uploader/types';

import { SMALL_FILE_SIZE_LIMIT } from './uploader-api';

export class Uploader {
  constructor(private readonly uploadApi: UploadApi) {}

  async upload(
    payload: UploadPayload,
    onProgressUpdate?: ProgressUpdateCallback
  ): Promise<string> {
    const { assetFile, previewFile } = payload;
    const previewFileSize = previewFile?.size ?? 0;
    if (assetFile.size + previewFileSize < SMALL_FILE_SIZE_LIMIT) {
      try {
        return await this.uploadApi.uploadSmallPayload(
          payload,
          onProgressUpdate
            ? this.createOnProgressUpdate(onProgressUpdate)
            : undefined
        );
      } catch (error) {
        if (error instanceof PayloadSizeError) {
          throw new FileUploadError('Wrong method used!');
        }

        throw new FileUploadError('Server error');
      }
    }

    try {
      return await this.uploadApi.uploadLargePayload(
        payload,
        onProgressUpdate
          ? this.createOnProgressUpdate(onProgressUpdate)
          : undefined
      );
    } catch (error) {
      if (error instanceof PayloadSizeError) {
        throw new FileUploadError('Wrong method used!');
      }

      throw new FileUploadError('Server error');
    }
  }

  private createOnProgressUpdate(callback: ProgressUpdateCallback) {
    return (progressEvent: any) => {
      callback(Math.round((progressEvent.loaded * 100) / progressEvent.total));
    };
  }
}
