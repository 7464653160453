import { get } from '~/lib/request';
import { benefitRawToBenefitMapper } from '~/mappers/benefit.mapper';
import { getFreeport } from '~/services/get-freeport';
import { Benefit } from '~/types/benefit';
import { isBenefitRaw } from '~/types/benefit-raw';
import { JointAccount } from '~/types/joint-account';
import { isRawJointAccount, RawJointAccount } from '~/types/raw-joint-account';

export const createJointAccount = async (
  owners: string[],
  fractions: number[]
): Promise<string> => {
  const freeport = await getFreeport();
  const { hash } = await freeport.createJointAccount(owners, fractions);

  return hash;
};

export const getAccountBenefits = async (
  account: string
): Promise<Benefit[]> => {
  const response = await get(`/wallet/${account}/benefits`);
  const benefitsRaw = (await response.json()) as unknown[];

  return benefitsRaw.filter(isBenefitRaw).map(benefitRawToBenefitMapper);
};

export const balanceOfJAOwner = async (
  account: string,
  owner: string
): Promise<number> => {
  const freeport = await getFreeport();
  const balance = await freeport.balanceOfJAOwner(account, owner);

  return balance.toNumber();
};

export const distributeJointAccount = async (
  account: string,
  onConfirmed: () => void
): Promise<string> => {
  const freeport = await getFreeport();
  const tx = await freeport.distributeJointAccount(account);

  void tx.wait().then(() => {
    onConfirmed();
  });

  return tx.hash;
};

const getJointAccounts = async (url: string): Promise<JointAccount[]> => {
  const accountsRaw = (await (await get(url)).json()) as unknown[];

  return accountsRaw.filter(isRawJointAccount).map(
    (jointAccount: RawJointAccount): JointAccount => ({
      owner: jointAccount.owner,
      account: jointAccount.account,
      fraction: jointAccount.fraction,
    })
  );
};

export const getJointAccountsByOwner = async (
  owner: string
): Promise<JointAccount[]> =>
  getJointAccounts(`/joint-account/by-owner/${owner}`);

export const getJointAccountsByAccount = async (
  account: string
): Promise<JointAccount[]> =>
  getJointAccounts(`/joint-account/by-account/${account}`);
