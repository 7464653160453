import { FieldValidator } from 'final-form';
import { useMemo } from 'react';
import { Field, FieldInputProps } from 'react-final-form';

import { TextInput } from '~/components/form/fields/text-input';
import {
  max as maxValidator,
  min as minValidator,
  required as requiredValidator,
  SyncFieldValidator,
} from '~/components/form/fields/validators';
import { composeSyncValidators } from '~/components/form/fields/validators/utils';

type Props = {
  readOnly?: boolean;
  disabled?: boolean;
  min?: number;
  max?: number;
  helperText?: string;
  validate?: SyncFieldValidator;
  label?: string;
  name: string;
  size?: 'small' | 'medium';
  required?: boolean;
  inputMode?: 'numeric' | 'decimal';
  suffix?: string;
};

export const NumberInput = ({
  name,
  helperText,
  disabled = false,
  readOnly = false,
  min,
  max,
  required = false,
  validate,
  label,
  size = 'medium',
  inputMode = 'numeric',
  suffix,
}: Props) => {
  const fieldValidator: FieldValidator<number> | undefined = useMemo(() => {
    const validators = [];
    if (required) {
      validators.push(requiredValidator);
    }

    if (validate) {
      validators.push(validate);
    }

    if (min != null) {
      validators.push(minValidator(min));
    }

    if (max != null) {
      validators.push(maxValidator(max));
    }

    return validators.length === 0
      ? undefined
      : (composeSyncValidators(...validators) as FieldValidator<number>);
  }, [max, min, required, validate]);

  // eslint-disable-next-line unicorn/consistent-function-scoping
  const onFocus = (input: FieldInputProps<number>) => (event: any) => {
    const updatedEvent = { ...event };
    if (Number(updatedEvent.target.value) === 0) {
      updatedEvent.target.value = '';
      input.onChange(updatedEvent);
    }
  };

  return (
    <Field validate={fieldValidator} name={name}>
      {({ input, meta }) => (
        <TextInput
          helperText={helperText}
          disabled={disabled}
          readOnly={readOnly}
          type="number"
          inputMode={inputMode}
          input={input}
          meta={meta}
          label={label}
          size={size}
          multiline={false}
          onFocus={onFocus(input)}
          suffix={suffix}
        />
      )}
    </Field>
  );
};
