import {
  Card,
  CardContent,
  CardMedia,
  Chip,
  Stack,
  Typography,
} from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';

import { routes, RoutesEnum } from '~/constants/routes';
import { putUrlParam } from '~/lib/url';
import { shortenNftId, TOKEN_DECIMALS, TOKEN_TITLE } from '~/lib/utils';
import { useAssetMetadata } from '~/routes/wallet/hooks/use-asset-metadata';
import { getFreeport } from '~/services/get-freeport';
import { getMarketPlace } from '~/services/get-marketplace';
import { Token } from '~/types/token';

type Props = {
  nft: Token;
};

export const NftCard = ({ nft }: Props) => {
  const { nftId, quantity, supply, minter, collectionAddress } = nft;
  const { isLoading, metadata } = useAssetMetadata(nftId);
  const [minterPrice, setMinterPrice] = useState<number>(0);

  const getAssetPreviewUrl = (): string => {
    if (isLoading || !metadata.cid) {
      return '';
    }

    return `${process.env.REACT_APP_MEDIA_API_URL}/assets/v2/${minter}/${metadata.cid}/preview`;
  };

  // TODO: Get price from `nft` object (when supported by backend)
  const fetchMinterPrice = useCallback(async () => {
    try {
      if (collectionAddress) {
        const marketplace = await getMarketPlace();
        const result = await marketplace.getOffer(minter, nftId);
        setMinterPrice(result.toNumber() / TOKEN_DECIMALS);
      } else {
        const freeport = await getFreeport();
        const result = await freeport.getOffer(minter, nftId);
        setMinterPrice(result.toNumber() / TOKEN_DECIMALS);
      }
    } catch {
      // skip
    }
  }, [minter, nftId, collectionAddress]);

  useEffect(() => {
    void fetchMinterPrice();
  }, [fetchMinterPrice]);

  return (
    <NavLink
      to={putUrlParam(routes[RoutesEnum.NFT_ASSETS].url, {
        nftId,
      })}
    >
      <Card>
        <CardMedia component="img" height="140" src={getAssetPreviewUrl()} />
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
            {metadata.contentMetadata?.title}
          </Typography>
          <Typography gutterBottom variant="h6" component="div">
            {shortenNftId(nftId)}
          </Typography>
          <Stack direction="row">
            <Typography variant="subtitle1" sx={{ flexGrow: 1 }}>
              {quantity}/{supply}
            </Typography>
            <Typography variant="subtitle1">
              {Boolean(minterPrice) && (
                <Chip size="small" label={`${minterPrice} ${TOKEN_TITLE}`} />
              )}
            </Typography>
          </Stack>
        </CardContent>
      </Card>
    </NavLink>
  );
};
