import {
  ApplicationEnum,
  createNFTAttachment,
  Deployment,
  getNFTAttachmentAddress,
  NFTAttachment,
} from '@cere/freeport-sdk';

import { APPLICATION, CONTRACTS_DEPLOYMENT } from '~/constants/env';
import { getSigner } from '~/services/get-signer';

let attachment: NFTAttachment | null = null;

export const getNftAttachment = async (): Promise<NFTAttachment> => {
  if (attachment) return attachment;

  const signer = await getSigner();
  const contractAddress = await getNFTAttachmentAddress(
    signer.provider,
    CONTRACTS_DEPLOYMENT as Deployment,
    APPLICATION as ApplicationEnum
  );

  attachment = createNFTAttachment({ signer, contractAddress });

  return attachment;
};
