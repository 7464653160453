import { isRecord } from './is-record';

export type RawJointAccount = {
  account: string;
  owner: string;
  fraction: number;
};

export const isRawJointAccount = (value: unknown): value is RawJointAccount =>
  isRecord(value) &&
  value.account !== undefined &&
  value.owner !== undefined &&
  value.fraction !== undefined;
