import { get } from '~/lib/request';
import { getSigner } from '~/services/get-signer';
import { buildMediaApiUrl } from '~/services/utils';
import { AssetDdc, isAssetDdc } from '~/types/asset-ddc';
import { DdcUploadResponse, isDdcUploadResponse } from '~/types/ddc';
import { isToken, Token } from '~/types/token';

const reportError = (response: Response): Error =>
  new Error(
    `Server response status code: ${response.status}, ${response.statusText}`
  );

export const getMintedTokens = async (account: string): Promise<Token[]> => {
  const tokens = (await (
    await get(`/wallet/${account}/nfts/minted`)
  ).json()) as unknown[];

  return tokens.filter((token: unknown): token is Token => isToken(token));
};

export const getOwnTokens = async (account: string): Promise<Token[]> => {
  const tokens = (await (
    await get(`/wallet/${account}/nfts/owned`)
  ).json()) as unknown[];

  return tokens.filter((token: unknown): token is Token => isToken(token));
};

export const getNft = async (nftId: string): Promise<Token> => {
  const token = (await (await get(`/nft/${nftId}`)).json()) as unknown[];

  if (!isToken(token)) {
    throw new Error('Not Found!');
  }

  return token;
};

export const getNftAssets = async (nftId: string): Promise<string[]> =>
  get(`/nft/${nftId}/cids`)
    .then(async (response) => response.json())
    .then((response) =>
      Array.isArray(response)
        ? response.filter((cid) => cid && typeof cid === 'string')
        : []
    );

export const getAssetFromDdc = async (
  minter: string,
  cid: string
): Promise<AssetDdc> => {
  const url = buildMediaApiUrl(`assets/v2/${minter}/${cid}`);
  return fetch(url.href)
    .then(async (response) => {
      if (response.ok) {
        return response.json();
      }

      throw reportError(response);
    })
    .then((response) => {
      if (isAssetDdc(response)) {
        return response;
      }

      throw reportError(response);
    });
};

export const getDdcUploadInfo = async (
  uploadId: string
): Promise<DdcUploadResponse> => {
  const url = buildMediaApiUrl('assets', 'v1', uploadId);
  return fetch(url.href)
    .then(async (response) => {
      if (!response.ok) {
        throw reportError(response);
      }

      return response.json();
    })
    .then((response) => {
      if (!isDdcUploadResponse(response)) {
        throw new Error(JSON.stringify(response));
      }

      return response;
    });
};

type DdcContentProps = {
  minter: string;
  owner: string;
  cid: string;
};

export const getAssetContentFromDdc = async ({
  minter,
  owner,
  cid,
}: DdcContentProps): Promise<Blob> => {
  const signer = await getSigner();
  const signature = await signer.signMessage(`${minter}${cid}${owner}`);
  const url = buildMediaApiUrl(`assets/v2/${minter}/${cid}/content`);
  return fetch(url.href, {
    headers: {
      'X-DDC-Signature': signature,
      'X-DDC-Address': owner,
    },
  }).then(async (response) => response.blob());
};
