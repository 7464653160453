import {
  ApplicationEnum,
  createSimpleAuction,
  Deployment,
  getSimpleAuctionAddress,
  SimpleAuction,
} from '@cere/freeport-sdk';

import { APPLICATION, CONTRACTS_DEPLOYMENT } from '~/constants/env';
import { getSigner } from '~/services/get-signer';

let simpleAuction: SimpleAuction | null = null;

export const getSimpleAuction = async (): Promise<SimpleAuction> => {
  if (simpleAuction) return simpleAuction;

  const signer = await getSigner();
  const contractAddress = await getSimpleAuctionAddress(
    signer.provider,
    CONTRACTS_DEPLOYMENT as Deployment,
    APPLICATION as ApplicationEnum
  );

  simpleAuction = createSimpleAuction({ signer, contractAddress });

  return simpleAuction;
};
