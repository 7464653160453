import { Collection, createCollection } from '@cere/freeport-sdk';

import { getSigner } from '~/services/get-signer';

export const getCollection = async (
  collectionAddress: string
): Promise<Collection> => {
  const signer = await getSigner();

  return createCollection({ signer, contractAddress: collectionAddress });
};
