import { Close as CloseIcon } from '@mui/icons-material';
import { Box, IconButton, Typography } from '@mui/material';
import { getIn } from 'final-form';
import { ReactElement, useContext, useEffect } from 'react';
import { useForm } from 'react-final-form';
import { useFieldArray } from 'react-final-form-arrays';

import { Input, Textarea } from '~/components/form/fields';
import { HelpPopover } from '~/components/icons/help-popover';
import { FlexBox } from '~/components/layout/flex-box';
import { AppContext } from '~/context/app-context';
import { ItemLayoutContext } from '~/context/item-layout-context';
import { useAbortSignal } from '~/lib/hooks/use-abort-signal';
import { isStringEqual } from '~/lib/utils';
import { getAssetFromDdc } from '~/services/assets.service';
import { AssetDdcContentType } from '~/types/asset-ddc';

import { AssetContent } from './asset-content';
import { AssetDetails } from './types';

type Props = {
  assetDetails: AssetDetails;
  index: number;
  name: string;
};

export const AssetCid = ({
  name,
  assetDetails,
  index,
}: Props): ReactElement => {
  const { cid } = assetDetails;
  const { asset } = assetDetails;
  const { userPubKey } = useContext(AppContext);
  const { nft } = useContext(ItemLayoutContext);
  const { fields } = useFieldArray('assets');
  const { mutators, getState } = useForm();
  const signal = useAbortSignal();
  const { values } = getState();

  const isReadOnly = Boolean(cid);
  const allowClose = !isReadOnly;

  const { minter } = nft;
  const isOwner = isStringEqual(minter, userPubKey);

  useEffect(() => {
    if (cid) {
      void getAssetFromDdc(minter, cid).then((assetDdcContent) => {
        if (!signal.aborted) {
          mutators.updateAsset(index, assetDdcContent);
        }
      });
    }
  }, [cid, index, minter, mutators, signal]);

  if (!asset) {
    return <></>;
  }

  return (
    <Box key={name} sx={{ mb: 2 }}>
      {allowClose && (
        <Box
          color="text.primary"
          sx={{
            display: 'flex',
            justifyContent: 'end',
            position: 'relative',
            left: '0.5rem',
          }}
        >
          <IconButton
            title="Remove"
            color="secondary"
            onClick={() => {
              fields.remove(index);
            }}
          >
            <CloseIcon />
          </IconButton>
        </Box>
      )}
      <FlexBox sx={{ mb: 3 }}>
        <Input
          required
          minLength={3}
          type="text"
          label="Title"
          readOnly={isReadOnly}
          name={`${name}.asset.contentMetadata.title`}
        />
        <HelpPopover>
          <Typography sx={{ p: 2 }}>Asset title</Typography>
        </HelpPopover>
      </FlexBox>
      <FlexBox>
        <Textarea
          required
          label="Description"
          name={`${name}.asset.contentMetadata.description`}
          readOnly={isReadOnly}
        />
        <HelpPopover>
          <Typography sx={{ p: 2 }}>Asset description</Typography>
        </HelpPopover>
      </FlexBox>
      <Box sx={{ my: 4 }}>
        <AssetContent
          isOwner={isOwner}
          assetIndex={index}
          fileFieldName={`${name}.files`}
          cid={cid}
          contentUrl={getIn(values, `${name}.contentUrl`) as Blob}
          title={getIn(values, `${name}.asset.contentMetadata.title`) as string}
          type={
            getIn(
              values,
              `${name}.asset.contentMetadata.contentType`
            ) as AssetDdcContentType
          }
        />
      </Box>
    </Box>
  );
};
