import { isRecord } from '~/types/is-record';

export type BenefitRaw = {
  nftId: string;
  saleType: number;
  saleCut: number;
  minimumFee: number;
  fraction: number;
};

export const isBenefitRaw = (value: unknown): value is BenefitRaw =>
  isRecord(value) &&
  value.nftId !== undefined &&
  value.saleType !== undefined &&
  value.saleCut !== undefined &&
  value.minimumFee !== undefined &&
  value.fraction !== undefined;
