import { Box, CircularProgress, Grid, Typography } from '@mui/material';
import { ReactNode } from 'react';

type Props = {
  title?: string;
  children: ReactNode;
  isLoading?: boolean;
};

export const PageLayout = ({ title, children, isLoading }: Props) => (
  <>
    {title && (
      <Box sx={{ textAlign: 'center' }}>
        <Grid container columns={12}>
          <Grid item lg={6} md={8} sx={{ mx: 'auto' }}>
            <Typography variant="h1" sx={{ mb: 2 }}>
              {title}
            </Typography>
          </Grid>
        </Grid>
      </Box>
    )}
    {isLoading ? (
      <Box
        sx={{
          display: 'flex',
          minHeight: '200px',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <CircularProgress thickness={3} size={32} color="inherit" />
      </Box>
    ) : (
      children
    )}
  </>
);
