/* eslint-disable jsx-a11y/anchor-is-valid */
import { Box, Breadcrumbs, Container, Link, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { ReactElement, ReactNode } from 'react';
import { Link as RouterLink } from 'react-router-dom';

type Props = {
  title: string;
  children: ReactNode;
};

const useStyles = makeStyles(() => ({
  pageHeader: {
    fontSize: '2.25rem',
    fontWeight: 600,
  },
  container: {
    marginTop: '2rem',
  },
}));

export const PageLayoutV2 = ({ title, children }: Props): ReactElement => {
  const styles = useStyles();
  return (
    <Box sx={{ padding: '0 24px', marginTop: 3 }}>
      <Breadcrumbs separator=">" aria-label="breadcrumb">
        <RouterLink to="/">
          <Link underline="hover">Freeport</Link>
        </RouterLink>
        ,<span>{title}</span>
      </Breadcrumbs>
      <Container classes={{ root: styles.container }} maxWidth="md">
        <Typography classes={{ root: styles.pageHeader }} variant="h1">
          {title}
        </Typography>
        {children}
      </Container>
    </Box>
  );
};
