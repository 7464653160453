import { Divider, Stack } from '@mui/material';

import { AuthWrapper } from '~/components/auth/auth-wrapper';
import { Header } from '~/components/layout/header';
import { FileUploadProvider } from '~/context/file-upload-context';
import { uploader } from '~/model/uploader';
import { Router } from '~/routes/router';

import packageJson from '../../package.json';
import { AppFrame } from './app-frame';

export const App = () => {
  console.log(
    `%cFreeport Creator Suite Version: ${packageJson.version}`,
    `color:white; background-color:black; border-left: 1px solid yellow; padding: 4px;`
  );

  return (
    <AppFrame>
      <AuthWrapper>
        <FileUploadProvider uploader={uploader}>
          <Stack sx={{ height: '100%' }}>
            <Header />
            <Divider sx={{ mb: 11 }} />
            <Router />
          </Stack>
        </FileUploadProvider>
      </AuthWrapper>
    </AppFrame>
  );
};
