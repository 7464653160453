import { getIn } from 'final-form';
import { ReactElement, useCallback } from 'react';
import { DropzoneOptions } from 'react-dropzone';
import { Field, useField, useForm } from 'react-final-form';

import { FileDropzone } from '~/components/form/fields/file-uploader/file-dropzone';

type Props = Pick<
  DropzoneOptions,
  'accept' | 'maxFiles' | 'minSize' | 'maxSize' | 'disabled'
> & {
  helperText?: string;
  name: string;
  uploadProgress?: number;
};

export const FileDropzoneField = ({
  name,
  disabled,
  helperText,
  accept,
  maxFiles,
  minSize,
  maxSize,
  uploadProgress,
}: Props): ReactElement => {
  const form = useForm();
  const field = useField<File[]>(name);

  const onRemove = useCallback(
    (file: File) => {
      const value = getIn(form.getState().values, name) as File[];
      form.change(
        name,
        value.filter((uploadedFile) => uploadedFile !== file)
      );
    },
    [form, name]
  );

  const onDrop = useCallback(
    (files: File[]) => {
      const addedFiles = getIn(form.getState().values, name) as File[];
      if (maxFiles === 1) {
        form.change(name, files);
        return;
      }

      form.change(name, [...addedFiles, ...files].filter(Boolean));
    },
    [form, name, maxFiles]
  );

  return (
    <Field name={name}>
      {() => (
        <FileDropzone
          helperText={helperText}
          files={field.input.value}
          disabled={disabled}
          accept={accept}
          maxFiles={maxFiles}
          minSize={minSize}
          maxSize={maxSize}
          uploadProgress={uploadProgress}
          onRemove={onRemove}
          onDrop={onDrop}
        />
      )}
    </Field>
  );
};
