import { Box, styled } from '@mui/material';
import { ReactNode } from 'react';

type Props = {
  children: ReactNode;
  className?: string;
};

export const FlexBox = styled(({ className, children }: Props) => (
  <Box
    className={className}
    sx={{ display: 'flex', alignItems: 'center', gap: 1 }}
  >
    {children}
  </Box>
))``;
