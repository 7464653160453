import { FieldValidator } from 'final-form';
import { useMemo } from 'react';

import {
  maxLength as maxLengthValidator,
  minLength as minLengthValidator,
  required as requiredValidator,
  SyncFieldValidator,
} from '~/components/form/fields/validators';
import { composeSyncValidators } from '~/components/form/fields/validators/utils';

type Params = {
  maxLength?: number;
  minLength?: number;
  required?: boolean;
  validate?: SyncFieldValidator;
};

export const useTextInputValidator = ({
  minLength,
  maxLength,
  required,
  validate,
}: Params): FieldValidator<string> | undefined =>
  useMemo(() => {
    const validators: SyncFieldValidator[] = [];
    if (required) {
      validators.push(requiredValidator);
    }

    if (validate) {
      validators.push(validate);
    }

    if (minLength != null) {
      validators.push(minLengthValidator(minLength));
    }

    if (maxLength != null) {
      validators.push(maxLengthValidator(maxLength));
    }

    return validators.length === 0
      ? undefined
      : (composeSyncValidators(...validators) as FieldValidator<string>);
  }, [maxLength, minLength, required, validate]);
