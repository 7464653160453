import { ItemLayout } from '~/components/layout/item-layout';
import { TOKEN_DECIMALS, TOKEN_TITLE } from '~/lib/utils';
import { Offers } from '~/routes/offers/offers';

import { NftNavigation } from './nft-navigation';

export const NftOffers = () => (
  <ItemLayout navigation={NftNavigation}>
    <Offers tokenSymbol={TOKEN_TITLE} tokenDecimals={TOKEN_DECIMALS} />
  </ItemLayout>
);
