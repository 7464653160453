import { Divider, Typography } from '@mui/material';
import { FORM_ERROR, FormApi } from 'final-form';
import { useCallback, useContext, useMemo } from 'react';
import { Form } from 'react-final-form';

import { Input, NumberInput } from '~/components/form/fields';
import { HelpPopover } from '~/components/icons/help-popover';
import { FlexBox } from '~/components/layout/flex-box';
import { AppContext } from '~/context/app-context';
import { formatError } from '~/lib/formatters';
import { useMessages } from '~/lib/notificator';
import { safeTransferFrom } from '~/services/token.service';

import { SimpleForm } from './simple-form';

type Props = {
  tokenId: string;
  disabled?: boolean;
  collectionAddress?: string;
};

type FormValues = {
  toAccount: string;
  amount: number;
};

export const TokenTransfer = ({
  tokenId,
  collectionAddress,
  disabled = false,
}: Props) => {
  const { userPubKey } = useContext(AppContext);
  const { showMessage } = useMessages();

  const initialValues = useMemo(
    (): FormValues => ({ toAccount: '', amount: 0 }),
    []
  );

  const submit = useCallback(
    async (values: FormValues, api: FormApi<FormValues, FormValues>) => {
      const { toAccount, amount } = values;
      try {
        const txHash = await safeTransferFrom(
          userPubKey,
          toAccount,
          tokenId,
          amount.toString(),
          () => {
            showMessage('Your transaction has been confirmed');
          },
          collectionAddress
        );
        showMessage(
          `Transaction submitted successfully with hash: ${txHash}!`,
          'success'
        );
        api.restart();
        return null;
      } catch (error) {
        showMessage(`Error occurred. ${formatError(error)}`, 'error');
        return { [FORM_ERROR]: formatError(error) };
      }
    },
    [userPubKey, tokenId, collectionAddress, showMessage]
  );

  return (
    <Form onSubmit={submit} initialValues={initialValues}>
      {({ handleSubmit, pristine, submitting, hasValidationErrors }) => (
        <SimpleForm
          submitDisabled={pristine || hasValidationErrors || submitting}
          onSubmit={handleSubmit}
          submitButtonTitle="Transfer"
          actionInProgress={submitting}
          isValid={() => !hasValidationErrors}
        >
          <FlexBox sx={{ mb: 3 }}>
            <Input
              disabled={disabled}
              name="toAccount"
              label="To account"
              required
            />
            <HelpPopover>
              <Typography sx={{ p: 2, maxWidth: 360 }}>
                Receiving account address to receive the sent tokens
              </Typography>
              <Divider />
              <Typography sx={{ m: 2 }}>
                Account requirements: Ethereum/Polygon address
              </Typography>
            </HelpPopover>
          </FlexBox>
          <FlexBox sx={{ mb: 5 }}>
            <NumberInput
              min={1}
              label="Amount"
              name="amount"
              required
              inputMode="numeric"
            />
            <HelpPopover>
              <Typography sx={{ p: 2, maxWidth: 360 }}>
                Amount of tokens to send
              </Typography>
            </HelpPopover>
          </FlexBox>
        </SimpleForm>
      )}
    </Form>
  );
};
