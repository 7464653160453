import { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import { AppLoader } from '~/components/loader/app-loader';
import { useMessages } from '~/lib/notificator';
import { getNft } from '~/services/assets.service';
import { defaultToken, Token } from '~/types/token';

import { ItemLayoutContext } from './item-layout-context';

type Props = {
  children: ReactNode;
};

export const ItemLayoutProvider = ({ children }: Props) => {
  const [nft, setNft] = useState<Token>(defaultToken);
  const { nftId } = useParams<{ nftId: string }>();
  const [isReady, updateReady] = useState<boolean>(!nftId);
  const { showMessage } = useMessages();

  const fetchNft = useCallback(
    async (id: string) => {
      try {
        const fetchedNft = await getNft(id);
        setNft(fetchedNft);
      } catch (error) {
        showMessage(`Error occurred. ${String(error)}`, 'error');
      }

      updateReady(true);
    },
    [showMessage]
  );

  useEffect(() => {
    if (nftId) {
      void fetchNft(nftId);
    }
  }, [fetchNft, nftId]);

  const context = useMemo(() => ({ nft }), [nft]);

  return (
    <ItemLayoutContext.Provider value={context}>
      {isReady ? children : <AppLoader />}
    </ItemLayoutContext.Provider>
  );
};
