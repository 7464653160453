import { ItemLayout } from '~/components/layout/item-layout';
import { RoyaltiesView } from '~/routes/royalties/royalties-view';

import { NftNavigation } from './nft-navigation';

export const NftRoyalties = () => (
  <ItemLayout navigation={NftNavigation}>
    <RoyaltiesView />
  </ItemLayout>
);
