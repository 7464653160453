import { ReactElement, ReactNode, useCallback, useMemo, useState } from 'react';

import { UploadWatcher } from './upload-watcher';
import { UploadItem, UploadsContext } from './uploads-context';

type Props = {
  children: ReactNode;
};

export const UploadProvider = ({ children }: Props): ReactElement => {
  const [uploads, setUpload] = useState<Map<string, UploadItem>>(new Map());

  const watchUpload = useCallback((ddcUploads: UploadItem[]) => {
    setUpload((state) => {
      const map = new Map(state);
      for (const upload of ddcUploads) {
        map.set(upload.id, upload);
      }

      return map;
    });
  }, []);

  const context = useMemo(
    () => ({
      uploads,
      watchUpload,
    }),
    [uploads, watchUpload]
  );

  return (
    <UploadsContext.Provider value={context}>
      {[...uploads.values()].map((upload) => (
        <UploadWatcher upload={upload} key={upload.id} />
      ))}
      {children}
    </UploadsContext.Provider>
  );
};
