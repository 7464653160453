import { Box } from '@mui/material';
import { MouseEvent, useCallback, useState } from 'react';

import { Clipboard } from '~/components/icons/clipboard';
import { ClipboardCheck } from '~/components/icons/clipboard-check';

const TIMEOUT_MS = 2000;

type Props = {
  value: string;
};

export const CopyToClipboard = ({ value }: Props) => {
  const [clicked, setClicked] = useState(false);

  const onCopyClick = useCallback(
    async (event: MouseEvent<SVGElement>) => {
      event.stopPropagation();

      setClicked(true);
      await navigator.clipboard.writeText(value);
      setTimeout(() => {
        setClicked(false);
      }, TIMEOUT_MS);
    },
    [value]
  );

  return (
    <Box sx={{ display: 'inline-flex', verticalAlign: 'text-top', px: '2px' }}>
      {clicked ? <ClipboardCheck /> : <Clipboard onClick={onCopyClick} />}
    </Box>
  );
};
