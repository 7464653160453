import { Collection, Freeport } from '@cere/freeport-sdk';
import plusFill from '@iconify/icons-eva/plus-fill';
import { Icon } from '@iconify/react';
import { Box, Button, Container, Grid, Typography } from '@mui/material';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { HelpPopover } from '~/components/icons/help-popover';
import { Empty } from '~/components/layout/empty';
import { FlexBox } from '~/components/layout/flex-box';
import { PageLayout } from '~/components/layout/page-layout';
import { AppContext } from '~/context/app-context';
import { ItemLayoutContext } from '~/context/item-layout-context';
import { formatError } from '~/lib/formatters';
import { useMessages } from '~/lib/notificator';
import { CreateAuction } from '~/routes/auctions/create-auction';
import { getAuctions } from '~/services/auctions.service';
import { getCollection } from '~/services/get-collection';
import { getFreeport } from '~/services/get-freeport';
import { Auction } from '~/types/auction';

import { AuctionCard } from './auction-card';

const AUCTION_POLLING_INTERVAL = 2000;

export const AuctionList = () => {
  const history = useHistory();
  const { userPubKey } = useContext(AppContext);
  const [isAuctionsLoading, setAuctionsLoading] = useState<boolean>(false);
  const [isBalanceLoading, setBalanceLoading] = useState<boolean>(false);
  const [auctions, setAuctions] = useState<Auction[]>([]);
  const [creating, setCreating] = useState<boolean>(false);
  const [nftBalance, setNftBalance] = useState<number>(0);
  const { nft } = useContext(ItemLayoutContext);
  const { nftId, collectionAddress } = nft;
  const { showMessage } = useMessages();

  const fetchBalance = useCallback(
    async (collectionContractAddress?: string) => {
      try {
        setBalanceLoading(true);
        if (collectionContractAddress) {
          const collection: Collection = await getCollection(
            collectionContractAddress
          );
          const result = await collection.balanceOf(userPubKey, nftId);
          setNftBalance(result.toNumber());
        } else {
          const freeport: Freeport = await getFreeport();
          const result = await freeport.balanceOf(userPubKey, nftId);
          setNftBalance(result.toNumber());
        }
      } catch (error) {
        showMessage(`Failed to fetch balance. ${formatError(error)}`, 'error');
      } finally {
        setBalanceLoading(false);
      }
    },
    [nftId, showMessage, userPubKey]
  );

  const fetchAuctions = useCallback(async () => {
    try {
      setAuctionsLoading(true);
      const result = await getAuctions({ nftId, onlyActive: false });
      setAuctions(result);
    } catch (error) {
      showMessage(`Failed to fetch auctions. ${formatError(error)}`, 'error');
    } finally {
      setAuctionsLoading(false);
    }
  }, [nftId, showMessage]);

  // TODO: Check current auction of user for this NFT (get bid from SC)
  useEffect(() => {
    void fetchBalance(collectionAddress);
    void fetchAuctions();
  }, [collectionAddress, fetchAuctions, fetchBalance]);

  const create = () => {
    setCreating(true);
  };

  const back = () => {
    setCreating(false);
  };

  const loadNewAuction = async (): Promise<Auction> => {
    const previousAuctionsIds = new Set(auctions.map((a) => a.auctionId));
    return new Promise((resolve) => {
      const intervalId = setInterval(async () => {
        const polledAuctions = await getAuctions({ nftId, onlyActive: false });
        const newAuction = polledAuctions.find(
          (pa) => !previousAuctionsIds.has(pa.auctionId)
        );
        if (newAuction) {
          clearInterval(intervalId);
          resolve(newAuction);
        }
      }, AUCTION_POLLING_INTERVAL);
    });
  };

  const onSuccessCreate = async () => {
    setCreating(false);
    try {
      setAuctionsLoading(true);
      const newAuction = await loadNewAuction();
      history.push(`/nft/${nftId}/auctions/${newAuction.auctionId}`);
    } catch (error) {
      showMessage(`Failed to fetch auctions. ${formatError(error)}`, 'error');
    } finally {
      setAuctionsLoading(false);
    }
  };

  if (creating) {
    return <CreateAuction onBack={back} onSuccessCreate={onSuccessCreate} />;
  }

  return (
    <PageLayout isLoading={isAuctionsLoading || isBalanceLoading}>
      <Container sx={{ mb: 5 }}>
        <FlexBox sx={{ mb: 3, ml: 2 }}>
          <FlexBox sx={{ flexGrow: 1 }}>
            <Typography variant="h4">Auctions</Typography>
            <HelpPopover>
              <Typography sx={{ p: 2, maxWidth: 360 }}>
                Overview of all auctions that were initiated for this NFT
              </Typography>
            </HelpPopover>
          </FlexBox>
          <Box sx={{ mx: 2, flexShrink: 0 }}>
            <Typography sx={{ opacity: 0.7 }}>
              NFT balance: {nftBalance}
            </Typography>
          </Box>
          <FlexBox sx={{ flexShrink: 0 }}>
            <Button
              variant="contained"
              startIcon={<Icon icon={plusFill} />}
              onClick={create}
              // TODO: Disable this button after SimpleAuction update
              disabled={nftBalance <= 0}
            >
              Start Auction
            </Button>
            <HelpPopover>
              <Typography sx={{ p: 2, maxWidth: 360 }}>
                Auction one of the minted NFTs (of total quantity) to the
                highest bidder for a starting price and auction closing time
              </Typography>
            </HelpPopover>
          </FlexBox>
        </FlexBox>

        {auctions.length > 0 ? (
          <Grid container spacing={3}>
            {auctions.map((auction) => (
              // TODO: Highlight active auction of the user
              <Grid key={auction.auctionId} item xs={12} md={6}>
                <AuctionCard auction={auction} />
              </Grid>
            ))}
          </Grid>
        ) : (
          <Empty />
        )}
      </Container>
    </PageLayout>
  );
};
