import { Box, Container, Divider, Link, Typography } from '@mui/material';

import freeportLogo from '~/assets/freeport-logo.png';
import freeportLogoSmall from '~/assets/freeport-logo-small.png';
import iconGithub from '~/assets/icons/icon-github.svg';
import iconMinter from '~/assets/icons/icon-minter.svg';
import iconSDK from '~/assets/icons/icon-sdk.svg';
import iconSuite from '~/assets/icons/icon-suite.svg';
import ringsBackground from '~/assets/rings.svg';
import { ConnectWalletButton } from '~/components/auth/connect-wallet-button';
import { FlexBox } from '~/components/layout/flex-box';
import { PageLayout } from '~/components/layout/page-layout';
import { useUserPublicKey } from '~/context/app-context';

export const Landing = () => {
  const userPubKey = useUserPublicKey();

  return (
    <PageLayout>
      <Box component={Container} sx={{ m: 'auto' }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            minHeight: 500,
            backgroundImage: `url(${ringsBackground})`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center -70px',
            backgroundSize: '80.5%',
          }}
        >
          <Box
            component="img"
            sx={{
              marginTop: '76px',
              marginLeft: '-16px',
              maxHeight: 120,
              maxWidth: 120,
            }}
            alt="Freeport logo"
            src={freeportLogo}
          />
          <Typography variant="h1" sx={{ mt: 6.5 }}>
            Freeport
          </Typography>
          <Typography variant="h5" sx={{ mt: 1 }}>
            Built on Cere DDC
          </Typography>
          <Typography
            sx={{ mt: 3, opacity: 0.5, maxWidth: 250, textAlign: 'center' }}
          >
            The Decentralized Data Vault and all-in-one solution suite for NFTs
          </Typography>
          {!userPubKey && <ConnectWalletButton variant="contained" />}
        </Box>
      </Box>
      <Box
        sx={{
          backgroundColor: 'background.paper',
          alignSelf: 'bottom',
          px: 11,
        }}
      >
        <FlexBox sx={{ my: 4 }}>
          <Link
            href="https://www.cere.network/freeport.html"
            target="_blank"
            rel="noopener"
          >
            <Box
              component="img"
              alt="Freeport small logo"
              src={freeportLogoSmall}
              sx={{
                ml: -2.5,
                mt: -3,
                mr: -1.5,
                mb: -2.5,
                maxWidth: '68px',
              }}
            />
          </Link>
          <Typography
            sx={{
              fontSize: 24,
              fontWeight: 600,
              mr: 'auto',
            }}
          >
            Freeport
          </Typography>
          <FlexBox sx={{ gap: 9, mr: 3 }}>
            <FlexBox>
              <Box
                component="img"
                alt="Minter"
                src={iconMinter}
                sx={{ maxWidth: 24 }}
              />
              <Typography
                sx={{
                  fontSize: 15,
                  fontWeight: 600,
                  whiteSpace: 'nowrap',
                }}
              >
                Minter
              </Typography>
            </FlexBox>
            <FlexBox>
              <Box
                component="img"
                alt="Creator Suite"
                src={iconSuite}
                sx={{ maxWidth: 24 }}
              />
              <Typography
                sx={{
                  fontSize: 15,
                  fontWeight: 600,
                  whiteSpace: 'nowrap',
                }}
              >
                Creator Suite
              </Typography>
            </FlexBox>
            <FlexBox>
              <Box
                component="img"
                alt="Developer SDK"
                src={iconSDK}
                sx={{ maxWidth: 24 }}
              />
              <Typography
                sx={{
                  fontSize: 15,
                  fontWeight: 600,
                  whiteSpace: 'nowrap',
                }}
              >
                Developer SDK
              </Typography>
            </FlexBox>
          </FlexBox>
        </FlexBox>
        <Divider />
        <FlexBox sx={{ my: 4 }}>
          <Typography
            variant="body2"
            sx={{
              mr: 'auto',
            }}
          >
            ©2021 Freeport by Cere. All rights reserved
          </Typography>
          <Link
            href="https://github.com/Cerebellum-Network/Cere-Freeport"
            target="_blank"
            rel="noopener"
          >
            <Box
              component="img"
              alt="Source code"
              src={iconGithub}
              sx={{ width: 24, height: 24 }}
            />
          </Link>
        </FlexBox>
      </Box>
    </PageLayout>
  );
};
