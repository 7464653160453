import { createContext, useContext } from 'react';

import { AnalyticService } from '~/services/analytics/analitycs-service';

import { AppContextType } from './types';

export const AppContext = createContext<AppContextType>({
  userPubKey: '',
  saveUserPubKey: () => null,
  analyticsService: new AnalyticService([]),
});

export const useUserPublicKey = (): string => {
  const appContext = useContext(AppContext);
  return appContext.userPubKey;
};
