import './app.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import { ReactNode, StrictMode } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { HashRouter } from 'react-router-dom';
import { ToastProvider } from 'react-toast-notifications';

import { AppProvider } from '~/context/app-provider';
import { UploadProvider } from '~/context/uploads/upload-provider';
import { ThemeProvider } from '~/theme/theme-provider';

type Props = {
  children: ReactNode;
};

export const AppFrame = ({ children }: Props) => (
  <StrictMode>
    <HelmetProvider>
      <HashRouter>
        <ToastProvider>
          <ThemeProvider>
            <AppProvider>
              <UploadProvider>{children}</UploadProvider>
            </AppProvider>
          </ThemeProvider>
        </ToastProvider>
      </HashRouter>
    </HelmetProvider>
  </StrictMode>
);
