import briefcaseFill from '@iconify/icons-eva/briefcase-fill';
import imageFill from '@iconify/icons-eva/image-fill';
import imageOutline from '@iconify/icons-eva/image-outline';
import peopleFill from '@iconify/icons-eva/people-fill';
import { Icon, IconifyIcon } from '@iconify/react';
import { useParams } from 'react-router-dom';

import { GenericNavigation } from '~/components/layout/generic-navigation';
import { routes, RoutesEnum } from '~/constants/routes';
import { putUrlParam } from '~/lib/url';

export const AccountNavigation = () => {
  const { accountId } = useParams<{ accountId: string }>();

  const accountTabs: Array<[RoutesEnum, IconifyIcon]> = [
    [RoutesEnum.ACCOUNT_MINTED, imageOutline],
    [RoutesEnum.ACCOUNT_OWNED, imageFill],
    [RoutesEnum.ACCOUNT_BENEFITS, briefcaseFill],
    [RoutesEnum.ACCOUNT_JOINT_LIST, peopleFill],
  ];

  return (
    <GenericNavigation
      tabs={accountTabs.map(([route, icon]) => ({
        url: putUrlParam(routes[route].url, { accountId }),
        title: routes[route].title ?? '',
        icon: <Icon icon={icon} width={20} height={20} />,
      }))}
    />
  );
};
