import { ElementType } from 'react';

import { TOKEN_TITLE } from '~/lib/utils';
import { AccountBenefits } from '~/routes/account/account-benefits';
import { AccountJointList } from '~/routes/account/account-joint-list';
import { AccountMinted } from '~/routes/account/account-minted';
import { AccountOwned } from '~/routes/account/account-owned';
import { CreateJointAccount } from '~/routes/joint-accounts/create-joint-account';
import { Landing } from '~/routes/landing/landing';
import { Mint } from '~/routes/mint/mint';
import { NftAssets } from '~/routes/nft/nft-assets';
import { NftAuction } from '~/routes/nft/nft-auction';
import { NftAuctions } from '~/routes/nft/nft-auctions';
import { NftOffers } from '~/routes/nft/nft-offers';
import { NftRoyalties } from '~/routes/nft/nft-royalties';
import { NftTransfer } from '~/routes/nft/nft-transfer';
import { TransferCere } from '~/routes/wallet/transfer-cere';

export enum RoutesEnum {
  CREATE_JOINT_ACCOUNT = 'CREATE_JOINT_ACCOUNT',
  TRANSFER_CERE_TOKENS = 'TRANSFER_CERE_TOKENS',
  MINT = 'MINT',
  LANDING = 'LANDING',

  ACCOUNT_MINTED = 'ACCOUNT_MINTED',
  ACCOUNT_OWNED = 'ACCOUNT_OWNED',
  ACCOUNT_BENEFITS = 'ACCOUNT_BENEFITS',
  ACCOUNT_JOINT_LIST = 'ACCOUNT_JOINT_LIST',

  NFT_ASSETS = 'NFT_ASSETS',
  NFT_ROYALTIES = 'NFT_ROYALTIES',
  NFT_OFFERS = 'NFT_OFFERS',
  NFT_AUCTIONS = 'NFT_AUCTIONS',
  NFT_AUCTION = 'NFT_AUCTION',
  NFT_TRANSFER = 'NFT_TRANSFER',
}

type RouteItem = {
  url: string;
  component: ElementType;
  title?: string;
  exact?: boolean;
};

export const routes: { [key in RoutesEnum]: RouteItem } = {
  [RoutesEnum.ACCOUNT_MINTED]: {
    url: '/account/:accountId/minted',
    component: AccountMinted,
    title: 'Minted',
  },
  [RoutesEnum.ACCOUNT_OWNED]: {
    url: '/account/:accountId/owned',
    component: AccountOwned,
    title: 'Owned',
  },
  [RoutesEnum.ACCOUNT_BENEFITS]: {
    url: '/account/:accountId/benefits',
    component: AccountBenefits,
    title: 'Benefits',
  },
  [RoutesEnum.ACCOUNT_JOINT_LIST]: {
    url: '/account/:accountId/joint-list',
    component: AccountJointList,
    title: 'Joint Accounts',
  },
  [RoutesEnum.NFT_ASSETS]: {
    url: '/nft/:nftId/assets',
    component: NftAssets,
    title: 'Assets',
  },
  [RoutesEnum.NFT_OFFERS]: {
    url: '/nft/:nftId/offers',
    component: NftOffers,
    title: 'Sales',
  },
  [RoutesEnum.NFT_AUCTION]: {
    url: '/nft/:nftId/auctions/:auctionId',
    component: NftAuction,
    title: 'Auction',
  },
  [RoutesEnum.NFT_AUCTIONS]: {
    url: '/nft/:nftId/auctions',
    component: NftAuctions,
    title: 'Auctions',
  },
  [RoutesEnum.NFT_ROYALTIES]: {
    url: '/nft/:nftId/royalties',
    component: NftRoyalties,
    title: 'Royalties',
  },
  [RoutesEnum.NFT_TRANSFER]: {
    url: '/nft/:nftId/transfer',
    component: NftTransfer,
    title: 'Transfer',
  },

  [RoutesEnum.TRANSFER_CERE_TOKENS]: {
    url: '/transfer-cere',
    component: TransferCere,
    title: `Transfer ${TOKEN_TITLE}`,
  },
  [RoutesEnum.CREATE_JOINT_ACCOUNT]: {
    url: '/create-joint-account',
    component: CreateJointAccount,
    title: 'Create JA',
  },
  [RoutesEnum.MINT]: { url: '/mint', component: Mint, title: 'Mint' },
  [RoutesEnum.LANDING]: { url: '/', component: Landing, exact: true }, // Should be the last item
};
