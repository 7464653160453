import {
  Card,
  Container,
  Link,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@mui/material';
import BoringAvatar from 'boring-avatars';
import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';

import { HelpPopover } from '~/components/icons/help-popover';
import { Empty } from '~/components/layout/empty';
import { FlexBox } from '~/components/layout/flex-box';
import { PageLayout } from '~/components/layout/page-layout';
import { colorsSchema } from '~/constants/avatar';
import { routes, RoutesEnum } from '~/constants/routes';
import { formatPercent } from '~/lib/formatters';
import { useMessages } from '~/lib/notificator';
import { putUrlParam } from '~/lib/url';
import { shortenNftId, TOKEN_TITLE } from '~/lib/utils';
import { getAccountBenefits } from '~/services/joint-accounts.service';
import { Benefit, SaleType } from '~/types/benefit';

const saleTypeString = {
  [SaleType.PRIMARY]: 'primary',
  [SaleType.SECONDARY]: 'secondary',
};

type BenefitsProps = {
  account: string;
};

export const Benefits = ({ account }: BenefitsProps) => {
  const [isLoading, updateLoading] = useState<boolean>(false);
  const [benefits, setBenefits] = useState<Benefit[]>([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const { showMessage } = useMessages();

  const fetchAccount = useCallback(
    async (accountToFetch: string) => {
      updateLoading(true);
      try {
        const fetchedBenefits = await getAccountBenefits(accountToFetch);
        setBenefits(fetchedBenefits);
      } catch (error) {
        showMessage(`Error occurred. ${String(error)}`, 'error');
      } finally {
        updateLoading(false);
      }
    },
    [showMessage]
  );

  const handleChangePage = useCallback((_, newPage: number) => {
    setPage(newPage);
  }, []);

  const handleChangeRowsPerPage = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setRowsPerPage(Number(event.target.value));
      setPage(0);
    },
    []
  );

  useEffect(() => {
    void fetchAccount(account);
  }, [account, fetchAccount]);

  return (
    <PageLayout isLoading={isLoading}>
      <Container sx={{ mb: 5 }}>
        <FlexBox sx={{ mb: 3, mx: 2 }}>
          <Typography variant="h4">Benefits</Typography>
          <HelpPopover>
            <Typography sx={{ p: 2, maxWidth: 360 }}>
              Overview of royalties paid to your account from NFT sales
            </Typography>
          </HelpPopover>
        </FlexBox>

        {benefits.length > 0 ? (
          <Card>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>NFT</TableCell>
                    <TableCell>Sale type</TableCell>
                    <TableCell>Cut of sales</TableCell>
                    <TableCell>Minimum amount</TableCell>
                    <TableCell>Share</TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {benefits.map(
                    ({ nftId, saleType, saleCut, minimumFee, fraction }) => (
                      <TableRow key={nftId}>
                        <TableCell>
                          <FlexBox>
                            <BoringAvatar
                              name={nftId}
                              colors={colorsSchema}
                              size={32}
                              variant="marble"
                            />
                            <Link
                              component={NavLink}
                              to={putUrlParam(
                                routes[RoutesEnum.NFT_ROYALTIES].url,
                                {
                                  nftId,
                                }
                              )}
                            >
                              {shortenNftId(nftId)}
                            </Link>
                          </FlexBox>
                        </TableCell>
                        <TableCell>{saleTypeString[saleType]}</TableCell>
                        <TableCell>{formatPercent(saleCut)}</TableCell>
                        <TableCell>
                          {minimumFee} {TOKEN_TITLE}
                        </TableCell>
                        <TableCell align="right">
                          {formatPercent(fraction)}
                        </TableCell>
                      </TableRow>
                    )
                  )}
                </TableBody>
              </Table>
            </TableContainer>

            <TablePagination
              rowsPerPageOptions={[5, 10, 20, 50]}
              component="div"
              count={benefits.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Card>
        ) : (
          <Empty />
        )}
      </Container>
    </PageLayout>
  );
};
