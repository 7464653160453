import { Tab, Tabs, useMediaQuery, useTheme } from '@mui/material';
import { JSXElementConstructor, ReactElement, SyntheticEvent } from 'react';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';

import { routes, RoutesEnum } from '~/constants/routes';
import { putUrlParam } from '~/lib/url';

type TabType = {
  url: string;
  title: string;
  icon: ReactElement<unknown, string | JSXElementConstructor<unknown>>;
};

type Props = {
  tabs: TabType[];
};

export const GenericNavigation = ({ tabs }: Props) => {
  const { pathname } = useLocation();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const history = useHistory();
  const match = useRouteMatch<{ nftId: string }>(
    routes[RoutesEnum.NFT_AUCTION].url
  );

  // TODO: Manage tab values properly
  const tabValue = match
    ? putUrlParam(routes[RoutesEnum.NFT_AUCTIONS].url, {
        nftId: match.params.nftId,
      })
    : pathname;

  const handleTabChange = (_: SyntheticEvent, newValue: string) => {
    history.push(newValue);
  };

  return (
    <Tabs
      value={tabValue}
      scrollButtons
      variant={isSmallScreen ? 'scrollable' : 'standard'}
      allowScrollButtonsMobile
      onChange={handleTabChange}
      centered={!isSmallScreen}
    >
      {tabs.map((tab) => (
        <Tab
          disableRipple
          key={tab.url}
          value={tab.url}
          icon={tab.icon}
          label={tab.title}
        />
      ))}
    </Tabs>
  );
};
