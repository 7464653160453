import { useCallback, useEffect, useState } from 'react';

import {
  getAssetFromDdc,
  getNft,
  getNftAssets,
} from '~/services/assets.service';
import { AssetMetadata } from '~/types/asset';

export const useAssetMetadata = (
  nftId: string
): { isLoading: boolean; metadata: AssetMetadata } => {
  const [isLoading, setLoading] = useState<boolean>(true);
  const [metadata, setMetadata] = useState<AssetMetadata>({});

  const loadAssetMetadata = useCallback(async () => {
    if (!nftId) {
      setLoading(false);
      return;
    }

    try {
      const nft = await getNft(nftId);
      const [initialAssetCid] = await getNftAssets(nftId);
      if (!initialAssetCid) {
        setLoading(false);
        return;
      }

      const initialAssetMetadata: AssetMetadata = {
        cid: initialAssetCid,
        ...(await getAssetFromDdc(nft.minter, initialAssetCid)),
      };
      setMetadata(initialAssetMetadata);
      setLoading(false);
    } catch {
    } finally {
      setLoading(false);
    }
  }, [nftId]);

  useEffect(() => {
    void loadAssetMetadata();
  }, [nftId, loadAssetMetadata]);

  return {
    isLoading,
    metadata,
  };
};
