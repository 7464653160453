import { createContext, useContext } from 'react';

import { DdcUploadResponse } from '~/types/ddc';

export type UploadItem = DdcUploadResponse & {
  nftId: string;
  title: string;
};

export type UploadsContextType = {
  uploads: Map<string, UploadItem>;
  watchUpload: (uploads: UploadItem[]) => void;
};

export const UploadsContext = createContext<UploadsContextType>({
  uploads: new Map(),
  watchUpload: () => {},
});

export const useUploadsWatcher = (): ((upload: UploadItem[]) => void) => {
  const { watchUpload } = useContext(UploadsContext);
  return watchUpload;
};
