import { AppBar, Box, Link, Toolbar, Typography } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

import { ReactComponent as DDCLogo } from '~/assets/ddc-logo.svg';
import { ConnectWalletButton } from '~/components/auth/connect-wallet-button';
import { HelpPopover } from '~/components/icons/help-popover';
import { useUserPublicKey } from '~/context/app-context';

import { AccountMenu } from './account-menu';
import { SearchBox } from './search-box';

export const Header = () => {
  const userPubKey = useUserPublicKey();

  return (
    <AppBar sx={{ backgroundColor: 'background.paper', zIndex: 1 }}>
      <Box component={Toolbar} sx={{ flexGrow: 1 }}>
        <RouterLink to={`/account/${userPubKey}/minted`}>
          <Box sx={{ display: 'inline-flex', verticalAlign: 'middle' }}>
            <DDCLogo width={40} />
          </Box>
        </RouterLink>
        <Typography
          component="div"
          variant="body2"
          sx={{
            ml: 1,
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <span>Cere Freeport</span>
          <span>NFT Creator Suite</span>
        </Typography>
        <SearchBox />
        <Box sx={{ mr: 3 }}>
          <Link
            href="https://github.com/Cerebellum-Network/Cere-Freeport"
            underline="none"
            target="_blank"
            rel="noopener"
          >
            Help
          </Link>
        </Box>
        {userPubKey ? (
          <AccountMenu />
        ) : (
          <>
            <ConnectWalletButton variant="outlined" />
            <HelpPopover sx={{ ml: 1 }}>
              <Typography sx={{ p: 2, maxWidth: 360 }}>
                Once you click this button a prompt from your MetaMask wallet
                should appear.
              </Typography>
            </HelpPopover>
          </>
        )}
      </Box>
    </AppBar>
  );
};
