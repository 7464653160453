import { isRecord } from '~/types/is-record';

export type DdcUploadResponse = {
  id: string;
  progress: {
    ENCRYPT: number;
    DDC_UPLOAD: number;
    CACHE: number;
  };
  result?: string;
  failed: boolean;
};

export const isDdcUploadResponse = (
  value: unknown
): value is DdcUploadResponse => isRecord(value);
