import { Avatar, Box, Container, Typography } from '@mui/material';
import BoringAvatar from 'boring-avatars';
import { useParams } from 'react-router-dom';

import { FlexBox } from '~/components/layout/flex-box';
import { Identicon } from '~/components/layout/identicon';
import { colorsSchema } from '~/constants/avatar';
import { useAssetMetadata } from '~/routes/wallet/hooks/use-asset-metadata';

export const SelectedItemStatus = () => {
  const { nftId } = useParams<{ nftId: string }>();
  const { accountId } = useParams<{ accountId: string }>();
  const { metadata } = useAssetMetadata(nftId);

  return (
    <Container>
      {nftId ? (
        <FlexBox>
          <BoringAvatar
            size={64}
            name={nftId}
            variant="marble"
            colors={colorsSchema}
          />
          <Typography component="h4" sx={{ px: 3, fontSize: '2rem' }}>
            NFT
          </Typography>
          <Box>
            <Typography component="h4" sx={{ px: 3, fontSize: '2rem' }}>
              {metadata.contentMetadata?.title}
            </Typography>
          </Box>
        </FlexBox>
      ) : (
        accountId && (
          <FlexBox>
            <Avatar
              alt={accountId}
              sx={{ bgcolor: 'white', width: 64, height: 64 }}
            >
              <Identicon value={accountId} />
            </Avatar>
            <Typography component="h4" sx={{ px: 3, fontSize: '2rem' }}>
              Account
            </Typography>
            <Box>{accountId}</Box>
          </FlexBox>
        )
      )}
    </Container>
  );
};
