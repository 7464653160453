import { useEffect, useMemo } from 'react';

export const useAbortSignal = (): AbortSignal => {
  const controller = useMemo(() => new AbortController(), []);
  useEffect(
    () => () => {
      controller.abort();
    },
    [controller]
  );
  return controller.signal;
};
