import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import {
  Avatar,
  Box,
  Chip,
  Divider,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';

import { Identicon } from '~/components/layout/identicon';
import { routes, RoutesEnum } from '~/constants/routes';
import { formatError } from '~/lib/formatters';
import { useMessages } from '~/lib/notificator';
import { putUrlParam } from '~/lib/url';
import { fractionToPercent, shortenHexAddress } from '~/lib/utils';
import { getJointAccountsByAccount } from '~/services/joint-accounts.service';
import { JointAccount } from '~/types/joint-account';

type LinkToAccountProps = { account: string };

const LinkToAccount = ({ account }: LinkToAccountProps) => (
  <Link
    component={NavLink}
    to={putUrlParam(routes[RoutesEnum.ACCOUNT_BENEFITS].url, {
      accountId: account,
    })}
  >
    {shortenHexAddress(account)}
  </Link>
);

type Props = { account: string };

export const RoyaltyAccount = ({ account }: Props) => {
  const [isMore, setIsMore] = useState<boolean>(false);
  const [pending, setPending] = useState<boolean>(true);
  const [jointAccounts, setJointAccounts] = useState<JointAccount[]>([]);
  const { showMessage } = useMessages();
  const hasShares = jointAccounts.length > 0;

  const fetchJointAccounts = useCallback(async () => {
    setPending(true);
    try {
      const result = await getJointAccountsByAccount(account);
      setJointAccounts(result);
    } catch (error) {
      showMessage(
        `Failed to fetch account details. ${formatError(error)}`,
        'error'
      );
    } finally {
      setPending(false);
    }
  }, [account, showMessage]);

  useEffect(() => {
    if (account) {
      void fetchJointAccounts();
    }
  }, [account, fetchJointAccounts]);

  const toggleMore = () => {
    setIsMore(!isMore);
  };

  return account ? (
    <Box sx={{ width: '100%', maxWidth: 226 }}>
      <List sx={{ p: 0 }}>
        <ListItem>
          <ListItemAvatar>
            <Avatar sx={{ bgcolor: 'white' }}>
              <Identicon value={account} />
            </Avatar>
          </ListItemAvatar>
          <ListItemText>
            <LinkToAccount account={account} />
          </ListItemText>
          {!pending && hasShares && (
            <IconButton onClick={toggleMore} size="small">
              {isMore ? <ExpandLess /> : <ExpandMore />}
            </IconButton>
          )}
        </ListItem>
      </List>
      {isMore && (
        <List sx={{ p: 0 }}>
          <Divider>Shares</Divider>
          {jointAccounts.map((share) => (
            <ListItem key={share.owner}>
              <ListItemAvatar>
                <Avatar sx={{ bgcolor: 'white' }}>
                  <Identicon value={share.owner} />
                </Avatar>
              </ListItemAvatar>
              <ListItemText>
                <LinkToAccount account={share.owner} />
              </ListItemText>
              <Chip
                label={
                  <Box sx={{ fontWeight: 500 }}>
                    {fractionToPercent(share.fraction)}%
                  </Box>
                }
                size="small"
                variant="outlined"
              />
            </ListItem>
          ))}
        </List>
      )}
    </Box>
  ) : (
    <Typography sx={{ opacity: 0.5 }}>No account</Typography>
  );
};
