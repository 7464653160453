export type Benefit = {
  nftId: string;
  saleType: SaleType;
  saleCut: number;
  minimumFee: number;
  fraction: number;
};

export enum SaleType {
  PRIMARY = 1,
  SECONDARY = 2,
}
