import { isRecord } from './is-record';

type RoyaltiesRaw = {
  primaryRoyaltyAccount: string;
  primaryRoyaltyCut: number;
  primaryRoyaltyMinimum: number;
  secondaryRoyaltyAccount: string;
  secondaryRoyaltyCut: number;
  secondaryRoyaltyMinimum: number;
};

export const isRoyaltiesRaw = (value: unknown): value is RoyaltiesRaw =>
  isRecord(value) &&
  value.primaryRoyaltyAccount !== undefined &&
  value.primaryRoyaltyCut !== undefined &&
  value.primaryRoyaltyMinimum !== undefined &&
  value.secondaryRoyaltyAccount !== undefined &&
  value.secondaryRoyaltyCut !== undefined &&
  value.secondaryRoyaltyMinimum !== undefined;
