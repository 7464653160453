import WalletIcon from '@mui/icons-material/AccountBalanceWallet';
import { Button } from '@mui/material';
import React, { useCallback, useState } from 'react';

import { ConnectWalletModal } from '~/components/auth/connect-wallet-modal';

export interface ConnectWalletButtonProps {
  variant: 'outlined' | 'contained';
}

export const ConnectWalletButton = ({ variant }: ConnectWalletButtonProps) => {
  const [isModalOpened, setModalOpened] = useState<boolean>(false);
  const toggleModal = useCallback(() => {
    setModalOpened((opened) => !opened);
  }, [setModalOpened]);

  return (
    <>
      <Button
        variant={variant}
        onClick={toggleModal}
        endIcon={<WalletIcon />}
        sx={variant === 'contained' ? { mt: 10, mb: 5 } : undefined}
      >
        Connect wallet
      </Button>
      <ConnectWalletModal open={isModalOpened} onClose={toggleModal} />
    </>
  );
};
