import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  Button,
  Divider,
  IconButton,
  Modal,
  Stack,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import React from 'react';

import { MetamaskIcon } from '~/components/icons/metamask';
import { WalletConnectIcon } from '~/components/icons/walletconnect';
import { NETWORK_ID } from '~/constants/env';
import { useWalletConnect } from '~/context/auth';
import { MetamaskConnector } from '~/model/wallet/metamask-connector';
import { WalletConnectConnector } from '~/model/wallet/wallet-connect-connector';

export interface ConnectWalletModalProps {
  open: boolean;
  onClose: () => void;
}

const style = {
  position: 'absolute' as const,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 343,
  border: '1px solid #000',
  bgcolor: 'background.paper',
  borderRadius: 1.5,
  boxShadow: 24,
};

const ConnectionButton = styled(Button)(({ theme }) => ({
  padding: theme.spacing(1.75),
  color: theme.palette.text.secondary,
  borderRadius: '1.5rem',
  justifyContent: 'left',
}));

export const ConnectWalletModal = ({
  open,
  onClose,
}: ConnectWalletModalProps) => {
  const connectWallet = useWalletConnect();

  const connectToMetamask = async () => {
    await connectWallet(new MetamaskConnector(Number(NETWORK_ID)));
    onClose();
  };

  const connectToWalletConnect = async () => {
    await connectWallet(new WalletConnectConnector());
    onClose();
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box sx={style}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            p: 2,
          }}
        >
          <Typography variant="h6" component="h2">
            Connect wallet
          </Typography>
          <IconButton size="small" onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Divider />
        <Box sx={{ p: 3 }}>
          <Stack spacing={2}>
            <ConnectionButton variant="outlined" onClick={connectToMetamask}>
              <MetamaskIcon />
              <Typography sx={{ marginLeft: 1.25 }}>Metamask</Typography>
            </ConnectionButton>
            <ConnectionButton
              variant="outlined"
              onClick={connectToWalletConnect}
            >
              <WalletConnectIcon />
              <Typography sx={{ marginLeft: 1.25 }}>WalletConnect</Typography>
            </ConnectionButton>
          </Stack>
        </Box>
      </Box>
    </Modal>
  );
};
