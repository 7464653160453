import { Box, Divider } from '@mui/material';
import { ElementType, ReactNode } from 'react';

import { ItemLayoutProvider } from '~/context/item-layout-provider';

import { SelectedItemStatus } from './selected-item-status';

type Props = {
  children: ReactNode;
  navigation: ElementType;
};

export const ItemLayout = ({ children, navigation }: Props) => {
  const Navigation = navigation;
  return (
    <div>
      <ItemLayoutProvider>
        <SelectedItemStatus />
        <Navigation />
        <Box component={Divider} sx={{ mb: 5 }} />
        {children}
      </ItemLayoutProvider>
    </div>
  );
};
