import settingsOutline from '@iconify/icons-eva/settings-outline';
import { Icon } from '@iconify/react';
import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Divider,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useCallback, useContext, useEffect, useState } from 'react';
import useSWR from 'swr';

import { HelpPopover } from '~/components/icons/help-popover';
import { Empty } from '~/components/layout/empty';
import { FlexBox } from '~/components/layout/flex-box';
import { PageLayout } from '~/components/layout/page-layout';
import { AppContext } from '~/context/app-context';
import { ItemLayoutContext } from '~/context/item-layout-context';
import { formatError } from '~/lib/formatters';
import { useMessages } from '~/lib/notificator';
import { isStringEqual } from '~/lib/utils';
import { getRoyalties } from '~/services/royalties.service';
import { Royalties } from '~/types/royalties';

import { ConfigureRoyalties } from './configure-royalties';
import { RoyaltyView } from './royalty-view';

export const RoyaltiesView = () => {
  const { showMessage } = useMessages();
  const [configuring, setConfiguring] = useState<boolean>(false);
  const { userPubKey } = useContext(AppContext);
  const { nft } = useContext(ItemLayoutContext);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const {
    data: royalties,
    error,
    mutate,
  } = useSWR<Royalties, Error>(
    [nft.nftId, nft.collectionAddress],
    getRoyalties
  );
  const pending = royalties === undefined;

  const configure = () => {
    setConfiguring(true);
  };

  const back = useCallback(async () => {
    setConfiguring(false);
    void (await mutate());
  }, [mutate]);

  useEffect(() => {
    if (error) {
      showMessage(`Failed to fetch royalty. ${formatError(error)}`, 'error');
    }
  }, [error, showMessage]);

  if (configuring && royalties) {
    return <ConfigureRoyalties nft={nft} royalties={royalties} onBack={back} />;
  }

  return (
    <PageLayout isLoading={pending}>
      <Container sx={{ mb: 5 }}>
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 3, mx: 2 }}>
          <FlexBox sx={{ flexGrow: 1 }}>
            <Typography variant="h4">Royalties</Typography>
            <HelpPopover>
              <Typography sx={{ p: 2, maxWidth: 360 }}>
                Overview of royalties earned from sales price as configured by
                the creator of this NFT
              </Typography>
            </HelpPopover>
          </FlexBox>
          {royalties && isStringEqual(userPubKey, nft.minter) && (
            <FlexBox sx={{ flexShrink: 1 }}>
              <Button
                color="primary"
                variant="outlined"
                startIcon={<Icon icon={settingsOutline} />}
                onClick={configure}
                disabled={!royalties}
              >
                Configure
              </Button>
              <HelpPopover>
                <Typography sx={{ p: 2, maxWidth: 360 }}>
                  Configure the amount of royalties per beneficiary for primary
                  sale and secondary market sale transfer for this NFT
                </Typography>
              </HelpPopover>
            </FlexBox>
          )}
        </Box>
        {royalties ? (
          <Card raised>
            <CardContent>
              <Stack
                spacing={2}
                direction={{ xs: 'column', sm: 'row' }}
                divider={
                  <Divider
                    orientation={isMobile ? 'horizontal' : 'vertical'}
                    flexItem
                  />
                }
              >
                <RoyaltyView
                  title="Primary"
                  account={royalties.primaryRoyaltyAccount}
                  cut={royalties.primaryRoyaltyCut}
                  minimum={royalties.primaryRoyaltyMinimum}
                />
                <RoyaltyView
                  title="Secondary"
                  account={royalties.secondaryRoyaltyAccount}
                  cut={royalties.secondaryRoyaltyCut}
                  minimum={royalties.secondaryRoyaltyMinimum}
                />
              </Stack>
            </CardContent>
          </Card>
        ) : (
          <Empty />
        )}
      </Container>
    </PageLayout>
  );
};
