import { importProvider } from '@cere/freeport-sdk';

export const getEncryptionKey = async (publicKey: string): Promise<string> => {
  const provider = importProvider();
  const encryptionKey: unknown = await provider.send(
    'eth_getEncryptionPublicKey',
    [publicKey]
  );
  return encryptionKey as string;
};
