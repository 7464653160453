import {
  ApplicationEnum,
  CollectionFactory,
  createCollectionFactory,
  Deployment,
  getCollectionFactoryAddress,
} from '@cere/freeport-sdk';

import { APPLICATION, CONTRACTS_DEPLOYMENT } from '~/constants/env';
import { getSigner } from '~/services/get-signer';

let collectionFactory: CollectionFactory | null = null;

export const getCollectionFactory = async (): Promise<CollectionFactory> => {
  if (collectionFactory) return collectionFactory;

  const signer = await getSigner();
  const contractAddress = await getCollectionFactoryAddress(
    signer.provider,
    CONTRACTS_DEPLOYMENT as Deployment,
    APPLICATION as ApplicationEnum
  );

  collectionFactory = createCollectionFactory({ signer, contractAddress });

  return collectionFactory;
};
